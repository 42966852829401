import React from 'react'
import VmView from '../vmview'
import {Spinner} from '@nextui-org/react'

type Props = {
  visible?: boolean | undefined
}

export default function VmSpinnerFullScreen({visible = false}: Props) {
  if (!visible) {
    return <></>
  }
  return (
    <VmView className="fixed inset-0 flex justify-center items-center bg-wht-ececec-100 bg-opacity-50 z-50">
      <VmView className="flex p-5 rounded-xl justify-center items-center bg-white">
        <Spinner />
      </VmView>
    </VmView>
  )
}
