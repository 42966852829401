import * as React from 'react'
function VmVmedisIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 12',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M4.64602 0C1.72566 -1.185e-07 0.451327 2.24368 0 3.53103C2.73451 2.84138 3.53097 4.66207 5.76106 8.27586C7.12878 10.4922 7.69912 10.7862 11.6814 12C11.8938 10.9517 10.7522 10.3724 9.95575 10.1241C9.31858 9.92552 8.59292 9.47126 8.30973 9.26897C8.43717 9.18069 10.823 10.2437 12 10.7862V9.87586C10.1097 9.41241 8.11504 7.91724 7.35398 7.22759C8.24602 7.95586 10.1504 8.68966 10.9912 8.96552C10.0265 7.57701 7.78893 4.94446 6.82301 4.55172C5.60177 4.05517 5.80298 2.25452 7.30088 2.89655C9.42478 3.8069 9.75221 3.10805 10.2212 2.73103C8.15044 3.06207 7.56637 1.18501e-07 4.64602 0Z',
      fill: '#DBEEF4',
    }),
    React.createElement('path', {
      d: 'M19.354 0C22.2743 -1.185e-07 23.5487 2.24368 24 3.53103C21.2655 2.84138 20.469 4.66207 18.2389 8.27586C16.8712 10.4922 16.3009 10.7862 12.3186 12C12.1062 10.9517 13.2478 10.3724 14.0442 10.1241C14.6814 9.92552 15.4071 9.47126 15.6903 9.26897C15.5628 9.18069 13.177 10.2437 12 10.7862V9.87586C13.8903 9.41241 15.885 7.91724 16.646 7.22759C15.754 7.95586 13.8496 8.68966 13.0088 8.96552C13.9735 7.57701 16.2111 4.94446 17.177 4.55172C18.3982 4.05517 18.197 2.25452 16.6991 2.89655C14.5752 3.8069 14.2478 3.10805 13.7788 2.73103C15.8496 3.06207 16.4336 1.18501e-07 19.354 0Z',
      fill: '#DBEEF4',
    }),
    React.createElement('path', {
      d: 'M6.85043 4.7364C6.46817 6.58997 10.1062 9.53568 11.9736 10.7773C13.8411 9.53568 17.4791 6.58997 17.0968 4.7364C16.6191 2.41968 12.9834 2.28129 11.9736 4.37636C10.9638 2.28129 7.32819 2.41968 6.85043 4.7364Z',
      fill: '#CC0001',
    }),
    React.createElement('path', {
      d: 'M11.3357 5.15866H12.61V9.29659H11.3357V5.15866Z',
      fill: 'white',
    }),
    React.createElement('path', {
      d: 'M13.964 6.56556V7.8897L9.98169 7.88969L9.98169 6.56556L13.964 6.56556Z',
      fill: 'white',
    })
  )
}
const ForwardRef = React.forwardRef(VmVmedisIcon)
export default ForwardRef
