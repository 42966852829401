import * as React from 'react'
function VmUserStarIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '-2 0 29 21',
        fill: 'currentColor',
        'aria-hidden': 'true',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M15.2727 0C16.43 0 17.5399 0.553123 18.3583 1.53769C19.1766 2.52226 19.6364 3.85761 19.6364 5.25C19.6364 6.64239 19.1766 7.97774 18.3583 8.96231C17.5399 9.94688 16.43 10.5 15.2727 10.5C14.1154 10.5 13.0055 9.94688 12.1872 8.96231C11.3688 7.97774 10.9091 6.64239 10.9091 5.25C10.9091 3.85761 11.3688 2.52226 12.1872 1.53769C13.0055 0.553123 14.1154 0 15.2727 0ZM15.2727 11.8125C18.1855 11.8125 24 13.5581 24 17.0625V21H6.54545V17.0625C6.54545 13.5581 12.36 11.8125 15.2727 11.8125ZM4.36364 12.18L1.63636 14.1356L2.37818 10.4475L0 7.98L3.13091 7.65187L4.36364 4.18688L5.57455 7.65187L8.72727 7.98L6.32727 10.4475L7.03636 14.1356L4.36364 12.18Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmUserStarIcon)
export default ForwardRef
