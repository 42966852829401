import * as React from 'react'

function VmLDewasaGrayIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        id: 'Layer_1',
        'data-name': 'Layer 1',
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 460 460',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M8026,3881.28v36c-.77,4.91-1.48,9.83-2.31,14.73q-19.06,112.9-118.82,169.11L7879,4061.95c-6.5-7.24-12.49-15-19.61-21.59-13-12-28.17-20.88-44.37-28-.18-4.14-.71-8.3-.42-12.4.18-2.62,1.56-5.15,2.4-7.73,3.64-.38,7.28-.71,10.91-1.15,40.47-4.89,61.77-41.81,57.34-75.62-3.39-25.89-6.24-51.84-9.5-77.74-1-7.89-2.57-15.7-3.88-23.54a29.78,29.78,0,0,0,1.48-4.2,242.87,242.87,0,0,0,3.67-69.61c-.38-4.05-2.72-4.27-5.8-4.28-47.74-.09-94.74,5.37-140.57,19.12-25.69,7.71-50,18.37-71.49,34.81-2.48,1.9-3.89,3.64-2.93,7.06,9.5,33.7,20.46,66.88,34.63,98.94.56,1.26,2,2.12,3.09,3.16-3.67,9.93-1.84,19.58,2.59,28.55,6,12,14.35,21.37,29.52,20.56l-3.74,1.75c4.94,7.56,14,15.19,21.41,17.84,1.82.65,4,.32,6,.44l1.23-.06c3.64,3.73,7,7.83,11,11.07,3.6,2.89,5.33,6.07,5,10.58-.13,1.8,0,3.63,0,5.44l-.07,16.94-36.08,22.88c-11,3-21.6,7.62-28.8,16.12-10.9,12.86-20.08,27.18-30,40.87q-82.09-53.77-102-149.85c-1.65-8-2.73-16-4.07-24.05q0-19,0-38c.75-5.42,1.44-10.85,2.27-16.25,14.81-97,89.09-174,185.15-191.92,7.83-1.46,15.72-2.56,23.58-3.82h38c6.39,1,12.78,2,19.17,3,91.7,14.61,168.15,87.63,186.76,178.54C8023.05,3860.2,8024.33,3870.78,8026,3881.28Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#ababab',
      },
    }),
    React.createElement('path', {
      d: 'M7730.89,4035.19l36.08-22.88c.23,2.14.52,4.28.67,6.42.39,5.7,3.46,9.69,8.38,12,9.84,4.69,20,5.06,29.6-.22,3.45-1.9,5.66-6.06,8.43-9.19l1-9c16.2,7.09,31.37,16,44.37,28,7.11,6.57,13.11,14.35,19.61,21.59a46.15,46.15,0,0,0,1.51,5.24c5.5,13.23,7.66,27.13,8.51,41.3-15.59,7.72-32.17,12.39-49.15,15.72-8.25,1.62-16.6,2.73-24.9,4.07h-39c-1.4-.35-2.79-.77-4.2-1q-13.89-2.52-27.8-5c1.21-1.53,3-2.89,3.53-4.63,2.92-9.67,6.72-19.29,8-29.21,2-16.25.67-32.26-10.26-46C7741.44,4037.69,7737.23,4034.55,7730.89,4035.19Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#797979',
      },
    }),
    React.createElement('path', {
      d: 'M7694,3899.2c-1-1-2.53-1.9-3.09-3.16-14.17-32.06-25.14-65.24-34.63-98.94-1-3.42.44-5.16,2.93-7.06,21.49-16.43,45.81-27.1,71.49-34.81,45.83-13.75,92.83-19.21,140.57-19.12,3.08,0,5.42.23,5.8,4.28a242.87,242.87,0,0,1-3.67,69.61,29.78,29.78,0,0,1-1.48,4.2c-12,4.37-23.9,9.14-36.07,13-31.58,10.13-64,16.52-96.85,20.82-3.68.48-4.83,1.58-4.28,5.31.79,5.25,1,10.58,1.46,15.88q1.78,17.22,3.55,34.44c.28,2.75,1.2,5.8-2.79,6.83-3.06,1.09-4-.42-5.32-3.13-2.68-5.41-5.43-11.25-9.67-15.33C7714.06,3884.49,7697.8,3884,7694,3899.2Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#363636',
      },
    }),
    React.createElement('path', {
      d: 'M7736.17,3869.25c-.46-5.3-.67-10.63-1.46-15.88-.56-3.73.6-4.83,4.28-5.31,32.84-4.3,65.27-10.69,96.85-20.82,12.16-3.9,24.05-8.67,36.07-13,1.31,7.85,2.88,15.66,3.88,23.54,3.26,25.9,6.11,51.86,9.5,77.74,4.42,33.82-16.87,70.73-57.34,75.62-3.63.44-7.27.77-10.91,1.15a38.91,38.91,0,0,0-4.37-.91c-33.06-3.12-58.66-26.66-64.31-59.26-3.21-18.5-6.25-37-9.52-55.51C7738.37,3874.06,7737.07,3871.69,7736.17,3869.25Zm79.91,84.9c6.28,7.13,14.1,8.57,23,6.23,8.81-2.8,12.59-9.49,14-18V3937a10.48,10.48,0,0,0-2.5-.19c-11.75,2.72-23.48,5.19-35.71,4.71-4.67-.18-3.59,3.08-1.76,5.78Zm-26.84-65.77,1-.67c-2.22-4.47-3.85-9.38-6.81-13.27s-7.39-2.69-9.5,1.73c-2.74,5.74-.48,16.06,4.41,20.21,3.77,3.2,7.66,2.34,9.51-2.25A40.23,40.23,0,0,0,7789.24,3888.38ZM7831,3913c1.38,1.14,1.72,1.64,2.13,1.72,5,1,11.79-3.88,12.54-9,.88-6-3.38-11.43-9.62-11.23-4.41.14-6.25-1.61-7.36-5.52-1.5-5.27-3.29-10.46-5.22-15.58-.62-1.66-2.06-3-3.12-4.52l-1.29.76c-.11.94-.53,2-.27,2.79,2.63,8.22,5.18,16.48,8.28,24.52.5,1.29,3.67,2.31,5.5,2.16,3.29-.27,6.58-1.13,7.94,2.85s-.83,6.6-4.42,8.26C7834.52,3910.92,7833.07,3911.84,7831,3913Zm12.37-43.13-1,.65c2.2,4.47,3.83,9.36,6.76,13.3,2.78,3.75,7.17,2.79,9.31-1.18,3.14-5.81.75-17-4.49-21-3.66-2.8-7.53-1.85-9.23,2.45A37.47,37.47,0,0,0,7843.33,3869.87Zm23.54-18.8c-1.92-1.59-2.65-2.55-3.61-2.94a34.67,34.67,0,0,0-21.49-1.28c-1,.24-1.65,1.7-2.47,2.6,1.19.59,2.34,1.6,3.57,1.71q9.54.87,19.12,1.3C7863.25,3852.53,7864.54,3851.77,7866.87,3851.08Zm-91.77,5.9c-5.85,2.69-11.88,5.3-17.71,8.28-1,.52-1.22,2.62-1.81,4,1.46-.06,3,.22,4.36-.25q8.13-2.92,16.11-6.27c1.43-.6,2.59-1.86,3.87-2.81Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#c8c8c8',
      },
    }),
    React.createElement('path', {
      d: 'M7744,4122.28c-20.45-4.1-39.54-11.86-57.83-21.71-4.8-2.59-9.39-5.58-14.07-8.38,9.91-13.7,19.09-28,30-40.87,7.21-8.5,17.85-13.14,28.8-16.12,6.34-.64,10.54,2.5,14.34,7.27,10.92,13.72,12.29,29.73,10.26,46-1.24,9.92-5,19.54-8,29.21C7747,4119.39,7745.21,4120.75,7744,4122.28Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#5a5a5a',
      },
    }),
    React.createElement('path', {
      d: 'M7694,3899.2c3.83-15.19,20.09-14.71,28-7.13,4.24,4.08,7,9.91,9.67,15.33,1.34,2.71,2.26,4.22,5.32,3.13l3.46,14.62c.43,1.69,0,4.38,1,5.14,6.59,5,3.48,11.76,3.83,17.87,0,.33-.93.72-1.62,1.22-2.2-2.54-3.93-6.25-8-3a13.44,13.44,0,0,0-3.67-.12,58,58,0,0,0-5.89,2.06c-15.17.81-23.57-8.52-29.52-20.56C7692.13,3918.78,7690.3,3909.12,7694,3899.2Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#afafaf',
      },
    }),
    React.createElement('path', {
      d: 'M7735.65,3946.38c4-3.26,5.77.45,8,3,.69-.5,1.64-.89,1.62-1.22-.35-6.12,2.75-12.92-3.83-17.87-1-.76-.58-3.44-1-5.14l-3.46-14.62c4-1,3.07-4.08,2.79-6.83q-1.75-17.22-3.55-34.44c.9,2.44,2.21,4.81,2.65,7.33,3.27,18.49,6.31,37,9.52,55.51,5.65,32.59,31.25,56.13,64.31,59.26a38.91,38.91,0,0,1,4.37.91c-.84,2.57-2.22,5.11-2.4,7.73-.28,4.1.24,8.26.42,12.4l-1,9c0-2.33,0-4.66,0-7,0-10.07,0-10.07-9.82-10.09-2.16,0-4.33.06-6.49,0a61.46,61.46,0,0,1-29.77-8.9c2.31-1.41,4.47-3.26,7-4.14,4.69-1.66,5.15-2.56,2-6.23-.73-.85-1.49-1.69-2.61-3-2.86,5.09-4.59,2-7.07-.29-5.2-4.71-10.79-9-16.22-13.46l-1.23.06A55.66,55.66,0,0,1,7735.65,3946.38Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#afafaf',
      },
    }),
    React.createElement('path', {
      d: 'M7767.94,3995.36a61.46,61.46,0,0,0,29.77,8.9c2.16.1,4.32,0,6.49,0,9.83,0,9.82,0,9.82,10.09,0,2.33,0,4.66,0,7-2.77,3.13-5,7.29-8.43,9.19-9.59,5.28-19.76,4.91-29.6.22-4.92-2.35-8-6.33-8.38-12-.15-2.15-.44-4.28-.67-6.42q0-8.47.07-16.94Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#c8c8c8',
      },
    }),
    React.createElement('path', {
      d: 'M7904.88,4101.12,7889,4108.5c-.85-14.18-3-28.08-8.51-41.3A46.15,46.15,0,0,1,7879,4062Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#5a5a5a',
      },
    }),
    React.createElement('path', {
      d: 'M7735.65,3946.38a55.66,55.66,0,0,0,14.12,22c-2-.12-4.19.21-6-.44-7.43-2.65-16.47-10.29-21.41-17.84l3.74-1.75a58,58,0,0,1,5.9-2.06A13.44,13.44,0,0,1,7735.65,3946.38Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#373737',
      },
    }),
    React.createElement('path', {
      d: 'M7767.94,3995.36H7767c0-1.81-.16-3.64,0-5.44.33-4.51-1.4-7.69-5-10.58-4-3.24-7.38-7.34-11-11.07,5.43,4.46,11,8.75,16.22,13.46,2.48,2.24,4.21,5.38,7.07.29,1.12,1.27,1.88,2.1,2.61,3,3.15,3.67,2.69,4.57-2,6.23C7772.41,3992.1,7770.25,3993.95,7767.94,3995.36Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#afafaf',
      },
    }),
    React.createElement('path', {
      d: 'M7853,3942.34c-1.37,8.56-5.15,15.24-14,18-.78-.23-1.85-.2-2.29-.72-5.65-6.6-12.88-7-20.66-5.51l-3.05-6.85C7826.67,3948.28,7839.93,3946.07,7853,3942.34Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#363636',
      },
    }),
    React.createElement('path', {
      d: 'M7789.24,3888.38a40.23,40.23,0,0,1-1.42,5.74c-1.85,4.6-5.73,5.45-9.51,2.25-4.9-4.14-7.15-14.46-4.41-20.21,2.1-4.42,6.55-5.6,9.5-1.73s4.6,8.81,6.81,13.27Zm-5.91-3.07c2.24-4.47,1.33-6.83-1.41-8.42-.52-.3-1.8.72-2.73,1.13Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#363636',
      },
    }),
    React.createElement('path', {
      d: 'M7831,3913c2.11-1.17,3.56-2.08,5.1-2.8,3.59-1.66,5.8-4.21,4.42-8.26s-4.65-3.12-7.94-2.85c-1.83.15-5-.87-5.5-2.16-3.1-8-5.65-16.3-8.28-24.52-.26-.82.16-1.86.27-2.79l1.29-.76c1.06,1.5,2.49,2.86,3.12,4.52,1.92,5.13,3.72,10.32,5.22,15.58,1.11,3.91,3,5.66,7.36,5.52,6.25-.2,10.5,5.21,9.62,11.23-.75,5.12-7.52,10-12.54,9C7832.68,3914.64,7832.35,3914.14,7831,3913Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#b0b0b0',
      },
    }),
    React.createElement('path', {
      d: 'M7843.33,3869.87a37.47,37.47,0,0,1,1.32-5.76c1.7-4.31,5.58-5.25,9.23-2.45,5.24,4,7.63,15.18,4.49,21-2.14,4-6.53,4.92-9.31,1.18-2.92-3.94-4.56-8.83-6.76-13.3Zm10.8,2.46,2.66-1.28c-1.08-2.09-2.11-4.22-3.36-6.21a15.75,15.75,0,0,0-2.75,1.13Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#373737',
      },
    }),
    React.createElement('path', {
      d: 'M7853,3942.34c-13.06,3.74-26.32,5.95-40,5-1.84-2.7-2.92-6,1.76-5.78,12.23.48,24-2,35.71-4.71a10.48,10.48,0,0,1,2.5.19Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#fdfdfd',
      },
    }),
    React.createElement('path', {
      d: 'M7866.87,3851.08c-2.32.69-3.62,1.45-4.88,1.4q-9.57-.44-19.12-1.3c-1.23-.11-2.38-1.12-3.57-1.71.82-.9,1.49-2.36,2.47-2.6a34.67,34.67,0,0,1,21.49,1.28C7864.22,3848.53,7865,3849.49,7866.87,3851.08Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#393939',
      },
    }),
    React.createElement('path', {
      d: 'M7775.1,3857l4.82,2.93c-1.28,1-2.44,2.21-3.87,2.81q-8,3.34-16.11,6.27c-1.31.47-2.9.19-4.36.25.58-1.37.79-3.47,1.81-4C7763.22,3862.28,7769.24,3859.67,7775.1,3857Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#383838',
      },
    }),
    React.createElement('path', {
      d: 'M7816.08,3954.15c7.78-1.51,15-1.08,20.66,5.51.44.52,1.51.49,2.29.72C7830.18,3962.72,7822.36,3961.28,7816.08,3954.15Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#909090',
      },
    }),
    React.createElement('path', {
      d: 'M7783.33,3885.31l-4.14-7.29c.93-.41,2.22-1.43,2.73-1.13C7784.66,3878.48,7785.56,3880.84,7783.33,3885.31Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#ebebeb',
      },
    }),
    React.createElement('path', {
      d: 'M7854.13,3872.33l-3.45-6.37a15.75,15.75,0,0,1,2.75-1.13c1.25,2,2.28,4.12,3.36,6.21Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#e3e3e3',
      },
    })
  )
}

const ForwardRef = React.forwardRef(VmLDewasaGrayIcon)
export default ForwardRef
