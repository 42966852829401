import * as React from 'react'
function VmChartLineUpIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M24 22.4348C24 22.8499 23.8595 23.248 23.6095 23.5416C23.3594 23.8351 23.0203 24 22.6667 24H1.33333C0.979711 24 0.640573 23.8351 0.390524 23.5416C0.140476 23.248 0 22.8499 0 22.4348V1.56522C0 1.1501 0.140476 0.751977 0.390524 0.458441C0.640573 0.164906 0.979711 0 1.33333 0C1.68696 0 2.02609 0.164906 2.27614 0.458441C2.52619 0.751977 2.66667 1.1501 2.66667 1.56522V14.4783L7.5 8.80435C7.62387 8.65843 7.77106 8.54265 7.93313 8.46365C8.0952 8.38465 8.26896 8.34398 8.44444 8.34398C8.61993 8.34398 8.79369 8.38465 8.95576 8.46365C9.11783 8.54265 9.26502 8.65843 9.38889 8.80435L12 11.8696L17.6667 5.21739H16.4444C16.0908 5.21739 15.7517 5.05249 15.5016 4.75895C15.2516 4.46541 15.1111 4.0673 15.1111 3.65217C15.1111 3.23705 15.2516 2.83893 15.5016 2.5454C15.7517 2.25186 16.0908 2.08696 16.4444 2.08696H20.8889C21.2425 2.08696 21.5816 2.25186 21.8317 2.5454C22.0817 2.83893 22.2222 3.23705 22.2222 3.65217V8.86957C22.2222 9.28469 22.0817 9.68281 21.8317 9.97634C21.5816 10.2699 21.2425 10.4348 20.8889 10.4348C20.5353 10.4348 20.1961 10.2699 19.9461 9.97634C19.696 9.68281 19.5556 9.28469 19.5556 8.86957V7.43478L12.9444 15.1957C12.8206 15.3416 12.6734 15.4574 12.5113 15.5364C12.3492 15.6154 12.1755 15.656 12 15.656C11.8245 15.656 11.6508 15.6154 11.4887 15.5364C11.3266 15.4574 11.1794 15.3416 11.0556 15.1957L8.44444 12.1304L2.66667 18.913V20.8696H22.6667C23.0203 20.8696 23.3594 21.0345 23.6095 21.328C23.8595 21.6215 24 22.0197 24 22.4348Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmChartLineUpIcon)
export default ForwardRef
