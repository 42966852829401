// SPView.tsx
import React, { ReactNode, HTMLProps } from "react";

interface VmViewProps extends HTMLProps<HTMLDivElement> {
  children?: ReactNode;
}

const VmView: React.FC<VmViewProps> = ({ children, ...props }) => {
  return <div {...props}>{children}</div>;
};

export default VmView;
