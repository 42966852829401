import * as React from 'react'
function VmListPencilIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        'aria-hidden': 'true',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      strokeWidth: 1,
      stroke: props?.fill || 'currentColor',
      d: 'M21.0672 11.6659L20.7837 12.0087L19.1849 10.076L19.6508 9.51272L19.6513 9.51214C19.7021 9.45062 19.7547 9.41112 19.8015 9.38767C19.8475 9.36459 19.8901 9.35575 19.9283 9.35575C19.9665 9.35575 20.0091 9.36459 20.0551 9.38767C20.1019 9.41112 20.1545 9.45062 20.2053 9.51214L20.2058 9.51273L21.0672 10.5541C21.3109 10.8487 21.3109 11.3713 21.0672 11.6659ZM18.9479 14.2131L13.1267 21.25H11.6696V19.1987C11.6696 19.1753 11.6724 19.1615 11.674 19.1554L17.3514 12.2923L18.9479 14.2131ZM12.5962 7.33333C12.5962 7.57031 12.5155 7.76591 12.4119 7.89111C12.3092 8.01525 12.2062 8.05 12.1329 8.05H1.21329C1.14 8.05 1.03699 8.01525 0.934297 7.89111C0.83073 7.76591 0.75 7.57031 0.75 7.33333C0.75 7.09635 0.83073 6.90075 0.934297 6.77556C1.03699 6.65142 1.14 6.61667 1.21329 6.61667H12.1329C12.2062 6.61667 12.3092 6.65142 12.4119 6.77556C12.5155 6.90075 12.5962 7.09635 12.5962 7.33333ZM1.21329 2.18333C1.14 2.18333 1.03699 2.14858 0.934297 2.02444C0.83073 1.89925 0.75 1.70365 0.75 1.46667C0.75 1.22969 0.83073 1.03409 0.934297 0.908892C1.03699 0.78475 1.14 0.75 1.21329 0.75H12.1329C12.2062 0.75 12.3092 0.78475 12.4119 0.908892C12.5155 1.03409 12.5962 1.22969 12.5962 1.46667C12.5962 1.70365 12.5155 1.89925 12.4119 2.02444C12.3092 2.14858 12.2062 2.18333 12.1329 2.18333H1.21329ZM7.27975 12.4833C7.35304 12.4833 7.45604 12.5181 7.55874 12.6422C7.66231 12.7674 7.74304 12.963 7.74304 13.2C7.74304 13.437 7.66231 13.6326 7.55874 13.7578C7.45604 13.8819 7.35304 13.9167 7.27975 13.9167H1.21329C1.14 13.9167 1.03699 13.8819 0.934297 13.7578C0.83073 13.6326 0.75 13.437 0.75 13.2C0.75 12.963 0.83073 12.7674 0.934297 12.6422C1.03699 12.5181 1.14 12.4833 1.21329 12.4833H7.27975Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmListPencilIcon)
export default ForwardRef
