import * as React from 'react'
function VmDiscountIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M8.33325 15.6667L15.6666 8.33333',
      strokeWidth: 1.5,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    React.createElement('path', {
      d: 'M15.6668 17.8667C16.8818 17.8667 17.8668 16.8817 17.8668 15.6667C17.8668 14.4516 16.8818 13.4667 15.6668 13.4667C14.4518 13.4667 13.4668 14.4516 13.4668 15.6667C13.4668 16.8817 14.4518 17.8667 15.6668 17.8667Z',
    }),
    React.createElement('path', {
      d: 'M8.3333 10.5333C9.54833 10.5333 10.5333 9.54836 10.5333 8.33333C10.5333 7.1183 9.54833 6.13333 8.3333 6.13333C7.11827 6.13333 6.1333 7.1183 6.1333 8.33333C6.1333 9.54836 7.11827 10.5333 8.3333 10.5333Z',
    }),
    React.createElement('path', {
      d: 'M1 12C1 13.4445 1.28452 14.8749 1.83733 16.2095C2.39013 17.5441 3.20038 18.7567 4.22183 19.7782C5.24327 20.7996 6.4559 21.6099 7.79048 22.1627C9.12506 22.7155 10.5555 23 12 23C13.4445 23 14.8749 22.7155 16.2095 22.1627C17.5441 21.6099 18.7567 20.7996 19.7782 19.7782C20.7996 18.7567 21.6099 17.5441 22.1627 16.2095C22.7155 14.8749 23 13.4445 23 12C23 10.5555 22.7155 9.12506 22.1627 7.79048C21.6099 6.4559 20.7996 5.24327 19.7782 4.22183C18.7567 3.20038 17.5441 2.39013 16.2095 1.83733C14.8749 1.28452 13.4445 1 12 1C10.5555 1 9.12506 1.28452 7.79048 1.83733C6.4559 2.39013 5.24327 3.20038 4.22183 4.22183C3.20038 5.24327 2.39013 6.4559 1.83733 7.79048C1.28452 9.12506 1 10.5555 1 12Z',
      strokeWidth: 1.5,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    })
  )
}
const ForwardRef = React.forwardRef(VmDiscountIcon)
export default ForwardRef
