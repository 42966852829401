import * as React from 'react'
function VmUserKeyIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '-2 0 29 21',
        fill: 'currentColor',
        'aria-hidden': 'true',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M5.8 7.875C5.4 6.3 4.3 5.25 3 5.25C1.3 5.25 0 6.95625 0 9.1875C0 11.4188 1.3 13.125 3 13.125C4.3 13.125 5.4 12.075 5.8 10.5H7V13.125H9V10.5H11V7.875H5.8ZM3 10.5C2.4 10.5 2 9.975 2 9.1875C2 8.4 2.4 7.875 3 7.875C3.6 7.875 4 8.4 4 9.1875C4 9.975 3.6 10.5 3 10.5ZM16 0C13.8 0 12 2.3625 12 5.25C12 8.1375 13.8 10.5 16 10.5C18.2 10.5 20 8.1375 20 5.25C20 2.3625 18.2 0 16 0ZM16 11.8125C13.3 11.8125 8 13.5187 8 17.0625V21H24V17.0625C24 13.5187 18.7 11.8125 16 11.8125Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmUserKeyIcon)
export default ForwardRef
