import * as React from 'react'

function VmLAnakGrayIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        id: 'Layer_1',
        'data-name': 'Layer 1',
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 461 461',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M7566,3916.28q0-17,0-34c.73-5.42,1.41-10.85,2.18-16.27,13.93-97.89,89.78-176.49,187.21-194,7.84-1.41,15.74-2.52,23.6-3.77h34c7.71,1.13,15.46,2,23.12,3.41,95.8,17.16,169.21,89.92,187.15,185.5,1.5,8,2.49,16.06,3.72,24.09v35c-1.17,7.87-2.08,15.78-3.54,23.6-9.92,53.3-35.47,97.78-76.2,133.45a209.35,209.35,0,0,1-57.15,36.08,140.3,140.3,0,0,0-2.72-48,51.85,51.85,0,0,0-12-23.22c-14.77-16.5-34.44-25.69-53.4-35.93l2-6c17.9-.28,34.25-5,47.74-17.28,18.54-16.84,23.5-38.41,20.79-62.33-2.38-21-5-42-7.74-63-1-7.55-2.59-15-3.91-22.52,3.11-3,5.75-6.83,9.42-8.73,12.34-6.37,14.27-17.07,12.26-29.3a104.62,104.62,0,0,1-1.49-13.28c-.36-10.29-4.52-17.83-14.33-22a7.31,7.31,0,0,1-3.68-4c-1.79-5.67-5.5-8.79-11.29-8.83-3.79,0-5.92-1.36-7.89-4.64-5.5-9.12-13.37-12.45-23.33-9.89-3.39.87-5.52.12-8-2-9.87-8.32-19.78-8.91-30.93-2.66a8.5,8.5,0,0,1-6.66.26c-8.09-3.6-15.94-3.33-23.51.9a10.89,10.89,0,0,1-9.36,1.09c-12-3.66-22.65-1.67-31.34,8.12-1.14,1.29-3.37,1.94-5.2,2.26-7.39,1.29-13.27,4.82-16.25,11.79-1.59,3.73-4.08,5.57-7.53,7.28-15.16,7.51-24.67,19-24.54,36.78,0,2.84-1.26,4-3.62,5.16-7.84,3.82-10.76,9.64-8.29,18,1.28,4.35.69,7.85-.89,11.87-3.72,9.52-4.16,19.08,3.29,27.14,2.84,3.08,4.09,5.93,4,10.07-.22,9.6,3.75,16.65,13.27,20.07a6,6,0,0,1,3.23,3.73c1.55,7.27,5.79,11.8,12.83,13.85-3.45,16,1.57,29.74,12.75,41,6.55,6.57,14.84,10.34,24.51,5.91,3.54-1.62,5.12.13,6.53,3.06a77.82,77.82,0,0,0,25,29.87c2.07,1.49,3.27,4.67,4.08,7.31.86,2.81.78,5.9,1.11,8.87l-.1,3.24a38.48,38.48,0,0,0-5.19,1.6c-11.62,5.41-23.23,10.84-34.77,16.41a42.94,42.94,0,0,0-8.92,5.41c-16.7,14-30.83,30.35-43.7,47.82-4.12,5.59-7.54,11.69-11.28,17.56q-79.06-52.46-99.94-145C7568.8,3937,7567.67,3926.6,7566,3916.28Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#ababab',
      },
    }),
    React.createElement('path', {
      d: 'M7822,4002.26c19,10.24,38.63,19.43,53.4,35.93a51.85,51.85,0,0,1,12,23.22,140.3,140.3,0,0,1,2.72,48c-15.83,7.61-32.54,12.5-49.72,15.82-8.09,1.56-16.26,2.71-24.39,4h-40c-3.53-.66-7-1.47-10.59-1.95a225.52,225.52,0,0,1-94.39-35.08c3.74-5.87,7.16-12,11.28-17.56,12.87-17.47,27-33.85,43.7-47.82a42.94,42.94,0,0,1,8.92-5.41c11.54-5.58,23.15-11,34.77-16.41a38.59,38.59,0,0,1,5.19-1.6c.08,6,.15,12,.26,18a14.37,14.37,0,0,0,8.64,13.54c10.1,4.83,20.32,4.59,30.27-.46,5.46-2.77,8.47-7.32,8.21-13.71-.17-4.15-.21-8.31-.31-12.47Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#797979',
      },
    }),
    React.createElement('path', {
      d: 'M7701,3904.23c-7-2.05-11.28-6.58-12.83-13.85a6,6,0,0,0-3.23-3.73c-9.52-3.42-13.49-10.47-13.27-20.07.1-4.13-1.15-7-4-10.07-7.45-8.06-7-17.62-3.29-27.14,1.57-4,2.16-7.52.89-11.87-2.46-8.38.46-14.2,8.29-18,2.36-1.15,3.64-2.32,3.62-5.16-.13-17.75,9.38-29.27,24.54-36.78,3.44-1.71,5.93-3.55,7.53-7.28,3-7,8.85-10.5,16.25-11.79,1.83-.32,4.05-1,5.2-2.26,8.68-9.79,19.33-11.78,31.34-8.12a10.89,10.89,0,0,0,9.36-1.09c7.57-4.24,15.42-4.5,23.51-.9a8.5,8.5,0,0,0,6.66-.26c11.15-6.25,21.06-5.66,30.93,2.66,2.52,2.12,4.64,2.88,8,2,10-2.56,17.83.77,23.33,9.89,2,3.28,4.1,4.61,7.89,4.64,5.79,0,9.5,3.16,11.29,8.83a7.31,7.31,0,0,0,3.68,4c9.81,4.14,14,11.68,14.33,22a104.62,104.62,0,0,0,1.49,13.28c2,12.23.08,22.93-12.26,29.3-3.66,1.89-6.31,5.76-9.42,8.73-6.31,1.89-12.59,3.89-18.94,5.62-2.2.6-5.07-.19-6.8.93-9.1,5.88-19.22,5.57-29.37,5.81a22.51,22.51,0,0,0-8.88,2.21c-8.41,4-16.73,7.58-26.2,3.5a4.35,4.35,0,0,0-2.87,0c-10.75,3.7-21.63,4.75-33.2,1.34.22,11.51-1.57,22-7.79,31.35-3.77,2.78-4.6,6.47-5.11,11.11-.63,5.75-2.85,11.48-5.23,16.84-2.66,6-7.81,8.79-14.55,8.35-6.28-.41-10.52-3.76-12.89-9.33C7707.44,3905.16,7705.1,3903.39,7701,3904.23Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#363636',
      },
    }),
    React.createElement('path', {
      d: 'M7746.82,3882c6.21-9.33,8-19.84,7.79-31.35,11.56,3.4,22.45,2.36,33.2-1.34a4.35,4.35,0,0,1,2.87,0c9.47,4.08,17.79.51,26.2-3.5a22.51,22.51,0,0,1,8.88-2.21c10.15-.24,20.27.07,29.37-5.81,1.74-1.12,4.6-.33,6.8-.93,6.35-1.73,12.63-3.73,18.94-5.62,1.32,7.5,2.93,15,3.91,22.52,2.73,21,5.36,41.95,7.74,63,2.71,23.93-2.24,45.49-20.79,62.33-13.5,12.26-29.84,17-47.74,17.28-2.14-.32-4.28-.69-6.42-1-29.76-3.91-53.8-25-60.25-54.32C7753,3921.47,7750.27,3901.64,7746.82,3882Zm77.15,76.37c6.24,7.5,14.28,8.2,23.06,6.07,8.77-2.78,12.08-9.8,13.94-17.95-.32-5.81-.65-6.22-6.13-4.86-10,2.5-20.07,4.38-30.49,3.81-5.66-.31-5.91.32-4.31,6Zm-43.49-71.66-.94.41c1.71,4,3,8.36,5.34,12a8,8,0,0,0,6.64,2.84c1.75-.28,3.76-3.09,4.44-5.17a16.77,16.77,0,0,0-2.71-15.57c-1.65-2.2-5.56-4.9-7.34-4.27-2.34.84-3.65,4.69-5.25,7.35C7780.29,3884.84,7780.53,3885.84,7780.49,3886.66Zm87-6.68.86-.4c-1.68-4-2.9-8.39-5.24-12a8.11,8.11,0,0,0-6.62-3c-1.74.24-3.86,3-4.5,5.08a17.52,17.52,0,0,0,3.75,17.17c1.46,1.71,4.78,3.29,6.66,2.77s3.3-3.6,4.55-5.76C7867.58,3882.83,7867.36,3881.3,7867.51,3880Zm-42.67-4.1-2,.72a35,35,0,0,0,.77,5c1.82,6.06,3.93,12,5.65,18.13,1.37,4.88,3,8.26,9.29,6.17,1.58-.53,5.4,1.68,6,3.41s-1.22,4.6-2.55,6.6c-.7,1.06-2.74,1.13-3.93,2a11.86,11.86,0,0,0-2.12,2.84c1.18.34,2.41,1.09,3.53,1,5.74-.67,10.6-6.06,10.24-11.09-.41-5.79-4.17-9.19-10.85-9.18-3.25,0-4.87-1.2-5.79-4.19q-2.5-8.12-5.4-16.11A37.24,37.24,0,0,0,7824.84,3875.88Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#c8c8c8',
      },
    }),
    React.createElement('path', {
      d: 'M7746.82,3882c3.45,19.68,6.22,39.52,10.51,59,6.44,29.31,30.49,50.41,60.25,54.32,2.15.28,4.28.64,6.42,1l-2,6q0,3,0,6c-16.3,1-32.1-.82-47-8.1-.33-3-.25-6.07-1.11-8.87-.81-2.64-2-5.82-4.08-7.31a77.82,77.82,0,0,1-25-29.87c-1.42-2.93-3-4.68-6.53-3.06-9.67,4.42-18,.65-24.51-5.91-11.18-11.21-16.2-24.91-12.75-41,4.08-.84,6.42.94,8,4.69,2.38,5.57,6.61,8.92,12.89,9.33,6.74.44,11.89-2.36,14.55-8.35,2.38-5.36,4.6-11.09,5.23-16.84C7742.22,3888.43,7743.05,3884.73,7746.82,3882Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#afafaf',
      },
    }),
    React.createElement('path', {
      d: 'M7775,4000.2c14.87,7.28,30.67,9.11,47,8.1.1,4.16.14,8.32.31,12.47.26,6.39-2.75,10.95-8.21,13.71-9.95,5-20.17,5.29-30.27.46a14.37,14.37,0,0,1-8.64-13.54c-.1-6-.17-12-.26-18Q7774.94,4001.83,7775,4000.2Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#c8c8c8',
      },
    }),
    React.createElement('path', {
      d: 'M7861,3946.45c-1.86,8.15-5.17,15.17-13.94,17.95-1.07-.34-2.53-.34-3.16-1.07-5.5-6.35-12.49-6.45-19.9-5l-3.94-6.89C7834,3952.28,7847.59,3950.26,7861,3946.45Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#363636',
      },
    }),
    React.createElement('path', {
      d: 'M7780.49,3886.66c0-.83-.2-1.82.18-2.45,1.6-2.65,2.91-6.51,5.25-7.35,1.77-.64,5.69,2.07,7.34,4.27a16.77,16.77,0,0,1,2.71,15.57c-.68,2.08-2.68,4.89-4.44,5.17a8,8,0,0,1-6.64-2.84c-2.37-3.6-3.62-7.93-5.34-12Zm9.92,1.82,2.61-1.4c-1.09-2.07-2.13-4.16-3.37-6.13a19.19,19.19,0,0,0-2.65,1.2Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#373737',
      },
    }),
    React.createElement('path', {
      d: 'M7867.51,3880c-.15,1.31.07,2.84-.54,3.89-1.25,2.17-2.63,5.23-4.55,5.76s-5.2-1.07-6.66-2.77a17.52,17.52,0,0,1-3.75-17.17c.64-2.06,2.76-4.84,4.5-5.08a8.11,8.11,0,0,1,6.62,3c2.34,3.62,3.56,8,5.24,12Zm-5.56-3.52,2.58-1.39c-1.05-2-2-4.14-3.29-6.07a19.33,19.33,0,0,0-3,1.33Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#363636',
      },
    }),
    React.createElement('path', {
      d: 'M7824.84,3875.88a37.24,37.24,0,0,1,2.89,5.25q2.9,8,5.4,16.11c.92,3,2.54,4.2,5.79,4.19,6.68,0,10.44,3.39,10.85,9.18.36,5-4.5,10.42-10.24,11.09-1.12.13-2.35-.61-3.53-1a11.86,11.86,0,0,1,2.12-2.84c1.18-.86,3.22-.94,3.93-2,1.33-2,3.17-4.89,2.55-6.6s-4.45-3.93-6-3.41c-6.3,2.09-7.92-1.3-9.29-6.17-1.71-6.09-3.82-12.07-5.65-18.13a35,35,0,0,1-.77-5Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#b0b0b0',
      },
    }),
    React.createElement('path', {
      d: 'M7861,3946.45c-13.38,3.81-27,5.83-40.93,5-1.61-5.72-1.35-6.36,4.31-6,10.43.58,20.47-1.31,30.49-3.81C7860.32,3940.23,7860.65,3940.64,7861,3946.45Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#fdfdfd',
      },
    }),
    React.createElement('path', {
      d: 'M7824,3958.33c7.41-1.45,14.39-1.35,19.9,5,.63.72,2.09.73,3.16,1.07C7838.25,3966.53,7830.22,3965.83,7824,3958.33Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#8f8f8f',
      },
    }),
    React.createElement('path', {
      d: 'M7790.41,3888.48l-3.42-6.33a19.19,19.19,0,0,1,2.65-1.2c1.24,2,2.29,4.06,3.37,6.13Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#f3f3f3',
      },
    }),
    React.createElement('path', {
      d: 'M7862,3876.47l-3.7-6.13a19.33,19.33,0,0,1,3-1.33c1.24,1.93,2.23,4,3.29,6.07Z',
      transform: 'translate(-7566 -3668.28)',
      style: {
        fill: '#e7e7e7',
      },
    })
  )
}

const ForwardRef = React.forwardRef(VmLAnakGrayIcon)
export default ForwardRef
