import * as React from 'react'

function VmMarkerGroupIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 40 40',
        fill: 'none',
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('circle', {
      cx: '50%',
      cy: '50%',
      r: 19,
    }),
    React.createElement('path', {
      fillOpacity: 0.2,
      fill: props?.stroke || 'black',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M19.562 29.314c.395 0 7.457-7.544 7.457-11.662a7.456 7.456 0 0 0-14.913 0c0 4.118 7.061 11.662 7.456 11.662Zm0-7.933a3.78 3.78 0 1 0 0-7.56 3.78 3.78 0 0 0 0 7.56Z',
    }),
    React.createElement('path', {
      fillOpacity: 0.5,
      fill: props?.stroke || 'black',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M12.106 17.652c0 4.118 7.061 11.662 7.456 11.662v-7.933a3.78 3.78 0 0 1 0-7.56v-3.626a7.456 7.456 0 0 0-7.456 7.457Z',
    })
  )
}

const ForwardRef = React.forwardRef(VmMarkerGroupIcon)
export default ForwardRef
