import * as React from 'react'

const VmEmptyBox = ({className = '', titleId = '', svgRef, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    fill="currentColor"
    aria-hidden="true"
    {...{className, 'aria-labelledby': titleId, ref: svgRef, ...props}}
  >
    <path
      opacity={0.1}
      d="M25.687 15.168c-.705-3.554-4.834-6.394-5.64-10.098C18.585.358 13.76-.387 10.625 1.66c-4.968 3.242.332 4.047-9.408 11.719-2.405 1.894-1.639 10.256 5.927 8.337 4.072-1.033 5.31-1.178 7.141-1.348 4.617-.428 12.586.767 11.403-5.2Z"
      fill="#367CFF"
    />
    <path
      d="m20.172 16.06-7.135 3.768-7.124-3.769v-5.69h14.26v5.69Z"
      fill="#fff"
    />
    <path
      d="M13.037 19.885a.057.057 0 0 1-.027-.007L5.886 16.11a.058.058 0 0 1-.03-.05v-5.691a.058.058 0 0 1 .057-.058h14.26a.058.058 0 0 1 .057.058v5.69a.057.057 0 0 1-.03.051l-7.136 3.768a.058.058 0 0 1-.027.007Zm-7.066-3.86 7.066 3.737 7.077-3.738v-5.598H5.971v5.598Z"
      fill="#262626"
    />
    <path
      d="m20.172 9.148-7.135-3.769 3.206-1.545 7.135 3.768-3.206 1.546Z"
      fill="#fff"
    />
    <path
      d="M20.172 9.205a.058.058 0 0 1-.027-.006L13.01 5.43a.057.057 0 0 1-.03-.052.058.058 0 0 1 .032-.051l3.206-1.545a.057.057 0 0 1 .052 0l7.135 3.769a.058.058 0 0 1-.002.103L20.198 9.2a.057.057 0 0 1-.025.005Zm-7.007-3.824 7.008 3.702L23.25 7.6l-7.008-3.702-3.077 1.483Z"
      fill="#262626"
    />
    <path
      d="m5.913 9.148 7.135-3.769-3.206-1.545-7.135 3.768 3.206 1.546Z"
      fill="#fff"
    />
    <path
      d="M5.913 9.205a.058.058 0 0 1-.025-.005L2.682 7.654a.058.058 0 0 1-.002-.103l7.135-3.768a.058.058 0 0 1 .052-.001l3.206 1.545a.058.058 0 0 1 .002.103L5.94 9.2a.058.058 0 0 1-.027.006ZM2.835 7.6l3.077 1.483L12.92 5.38 9.843 3.898 2.835 7.6Z"
      fill="#262626"
    />
    <path
      d="m5.913 9.147 7.135 3.769-3.669 2.008-7.135-3.769 3.669-2.008Z"
      fill="#fff"
    />
    <path
      d="M9.38 14.982a.058.058 0 0 1-.028-.007l-7.135-3.769a.057.057 0 0 1-.03-.05.058.058 0 0 1 .03-.051l3.668-2.008a.058.058 0 0 1 .055 0l7.135 3.768a.057.057 0 0 1 .03.05.059.059 0 0 1-.03.052l-3.668 2.007a.058.058 0 0 1-.028.008Zm-7.014-3.828 7.013 3.704 3.547-1.941-7.013-3.704-3.547 1.941Z"
      fill="#262626"
    />
    <path
      d="m20.172 9.147-7.135 3.769 3.669 2.008 7.135-3.769-3.669-2.008Z"
      fill="#fff"
    />
    <path
      d="M16.706 14.982a.059.059 0 0 1-.028-.008l-3.669-2.007a.058.058 0 0 1 .001-.102l7.135-3.769a.058.058 0 0 1 .055 0l3.669 2.009a.058.058 0 0 1-.001.101l-7.135 3.769a.058.058 0 0 1-.027.007Zm-3.547-2.065 3.547 1.941 7.013-3.704-3.547-1.94-7.013 3.703Z"
      fill="#262626"
    />
    <path
      d="M13.048 5.38 5.913 9.146l7.124 3.769 7.135-3.769-7.124-3.768ZM13.095 12.916h-.116v6.912h.116v-6.912Z"
      fill="#262626"
    />
    <path
      d="M9.04 8.805a.046.046 0 0 1-.038-.073c.008-.012.844-1.22-.089-2.321a1.757 1.757 0 0 0-1.345-.625h-.013c.089.308.073.581-.052.791a.585.585 0 0 1-.54.297.464.464 0 0 1-.402-.312c-.102-.268.005-.527.288-.695.179-.098.377-.156.582-.167a2.464 2.464 0 0 0-.258-.5c-.337-.515-.95-.87-1.684-.977-.622-.09-1.218.016-1.518.27a.046.046 0 1 1-.06-.07c.32-.271.944-.386 1.591-.292.76.11 1.397.482 1.749 1.018.114.17.206.354.276.547a1.848 1.848 0 0 1 1.457.655c.98 1.157.102 2.422.093 2.435a.046.046 0 0 1-.037.02ZM7.46 5.79a1.287 1.287 0 0 0-.564.158c-.244.144-.334.356-.248.582a.375.375 0 0 0 .323.253.493.493 0 0 0 .452-.252c.116-.193.126-.45.037-.74ZM3.173 5.239a.315.315 0 1 1-.181-.603.315.315 0 0 1 .18.603Zm-.154-.514a.222.222 0 1 0 .128.424.222.222 0 0 0-.128-.424ZM3.172 4.514a.314.314 0 1 1-.18-.602.314.314 0 0 1 .18.602ZM3.02 4a.222.222 0 1 0 .127.425A.222.222 0 0 0 3.019 4Z"
      fill="#292A2E"
    />
    <path
      d="M3.082 4.841c.228 0 .412-.123.412-.274 0-.152-.184-.275-.412-.275-.227 0-.41.123-.41.275 0 .151.183.274.41.274Z"
      fill="#fff"
    />
    <path
      d="M3.264 4.861a.636.636 0 0 1-.181.026c-.257 0-.458-.14-.458-.32s.2-.321.457-.321a.574.574 0 0 1 .317.088.262.262 0 0 1 0 .465.503.503 0 0 1-.135.062Zm-.335-.5c-.124.036-.211.116-.211.206 0 .124.167.228.365.228a.482.482 0 0 0 .265-.073.171.171 0 0 0 0-.311.537.537 0 0 0-.419-.05Z"
      fill="#292A2E"
    />
    <path
      d="m3.183 4.833-.01-.534-.132-.006-.01.546.152-.006ZM2.537 4.683a.042.042 0 1 0 0-.085.042.042 0 0 0 0 .085ZM2.57 4.456a.042.042 0 1 0 0-.085.042.042 0 0 0 0 .085ZM16.305 6.139a.046.046 0 0 1-.044-.033 4.588 4.588 0 0 1-.12-1.758c.119-.86.593-1.989 2.174-2.483.222-.071.45-.124.682-.156.074-.465.357-.97.991-1.318 1.506-.828 2.523-.085 2.533-.078a.046.046 0 0 1-.021.083.046.046 0 0 1-.035-.009c-.01-.007-.981-.713-2.432.085-.593.325-.864.792-.94 1.226.697-.069 1.03.164 1.18.34a.716.716 0 0 1 .107.782.56.56 0 0 1-.547.32c-.39-.023-.711-.35-.82-.835a1.575 1.575 0 0 1-.03-.499c-.217.031-.431.08-.64.148-1.233.385-1.943 1.195-2.111 2.407a4.483 4.483 0 0 0 .117 1.718.047.047 0 0 1-.023.055.046.046 0 0 1-.021.005Zm2.773-4.344c-.019.163-.01.329.024.49.1.443.39.742.737.763a.47.47 0 0 0 .459-.269.623.623 0 0 0-.095-.682c-.216-.253-.61-.356-1.125-.302ZM23.598.78a.33.33 0 1 1 .001-.66.33.33 0 0 1-.001.66Zm0-.567a.238.238 0 1 0 0 .475.238.238 0 0 0 0-.475ZM23.459 1.535a.331.331 0 1 1 0-.662.331.331 0 0 1 0 .662Zm0-.568a.238.238 0 0 0-.195.104.236.236 0 1 0 .196-.104Z"
      fill="#292A2E"
    />
    <path
      d="M23.955.916c.03-.158-.139-.322-.375-.365-.237-.044-.452.049-.481.207-.03.158.139.321.375.365.237.043.452-.05.481-.207Z"
      fill="#fff"
    />
    <path
      d="M23.59 1.18a.693.693 0 0 1-.124-.012.606.606 0 0 1-.311-.152.296.296 0 0 1-.102-.267.297.297 0 0 1 .19-.213.645.645 0 0 1 .656.121.297.297 0 0 1 .102.267.297.297 0 0 1-.19.213.56.56 0 0 1-.22.043Zm-.127-.594a.466.466 0 0 0-.183.035.208.208 0 0 0-.136.145.209.209 0 0 0 .075.184.553.553 0 0 0 .555.102.208.208 0 0 0 .136-.145.208.208 0 0 0-.075-.183.513.513 0 0 0-.264-.128.599.599 0 0 0-.108-.01Z"
      fill="#292A2E"
    />
    <path
      d="m23.474.54-.093.558.137.031.115-.566-.16-.023ZM24.031 1.14a.045.045 0 1 0 0-.09.045.045 0 0 0 0 .09ZM24.11.91a.045.045 0 1 0 0-.09.045.045 0 0 0 0 .09Z"
      fill="#292A2E"
    />
  </svg>
)

export default VmEmptyBox
