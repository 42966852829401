import * as React from 'react'
function VmUserArrowRightIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        'aria-hidden': 'true',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M20 19H21V18V17.6302L22.662 19.5L21 21.3698V21V20H20H15.6667V19H20ZM6.33333 6C6.33333 3.1383 8.39309 1 10.6667 1C12.9402 1 15 3.1383 15 6C15 8.8617 12.9402 11 10.6667 11C8.39309 11 6.33333 8.8617 6.33333 6ZM1 21C1 19.8743 1.83119 18.6423 3.62359 17.6341C5.38234 16.6448 7.87404 16 10.6667 16C11.0291 16 11.3734 16.0253 11.7321 16.0566C11.2462 17.4029 11 18.7809 11 20.25C11 21.2315 11.1285 22.1352 11.3369 23H1V21Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmUserArrowRightIcon)
export default ForwardRef
