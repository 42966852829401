import * as React from 'react'
function VmCurrentLocationIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M12 24C11.6895 24 11.4294 23.8952 11.2197 23.6855C11.0093 23.4751 10.9041 23.2146 10.9041 22.9041V21.8082C8.621 21.5525 6.66228 20.6075 5.02794 18.9732C3.39288 17.3381 2.44749 15.379 2.19178 13.0959H1.09589C0.785388 13.0959 0.524932 12.9907 0.314521 12.7803C0.10484 12.5706 0 12.3105 0 12C0 11.6895 0.10484 11.429 0.314521 11.2186C0.524932 11.0089 0.785388 10.9041 1.09589 10.9041H2.19178C2.44749 8.621 3.39288 6.66192 5.02794 5.02685C6.66228 3.39251 8.621 2.44749 10.9041 2.19178V1.09589C10.9041 0.785388 11.0093 0.524932 11.2197 0.314521C11.4294 0.10484 11.6895 0 12 0C12.3105 0 12.571 0.10484 12.7814 0.314521C12.991 0.524932 13.0959 0.785388 13.0959 1.09589V2.19178C15.379 2.44749 17.3381 3.39251 18.9732 5.02685C20.6075 6.66192 21.5525 8.621 21.8082 10.9041H22.9041C23.2146 10.9041 23.4751 11.0089 23.6855 11.2186C23.8952 11.429 24 11.6895 24 12C24 12.3105 23.8952 12.5706 23.6855 12.7803C23.4751 12.9907 23.2146 13.0959 22.9041 13.0959H21.8082C21.5525 15.379 20.6075 17.3381 18.9732 18.9732C17.3381 20.6075 15.379 21.5525 13.0959 21.8082V22.9041C13.0959 23.2146 12.991 23.4751 12.7814 23.6855C12.571 23.8952 12.3105 24 12 24ZM12 19.6712C14.1187 19.6712 15.9269 18.9224 17.4247 17.4247C18.9224 15.9269 19.6712 14.1187 19.6712 12C19.6712 9.88128 18.9224 8.07306 17.4247 6.57534C15.9269 5.07762 14.1187 4.32877 12 4.32877C9.88128 4.32877 8.07306 5.07762 6.57534 6.57534C5.07762 8.07306 4.32877 9.88128 4.32877 12C4.32877 14.1187 5.07762 15.9269 6.57534 17.4247C8.07306 18.9224 9.88128 19.6712 12 19.6712ZM12 16.3836C10.7945 16.3836 9.76256 15.9543 8.90411 15.0959C8.04566 14.2374 7.61644 13.2055 7.61644 12C7.61644 10.7945 8.04566 9.76256 8.90411 8.90411C9.76256 8.04566 10.7945 7.61644 12 7.61644C13.2055 7.61644 14.2374 8.04566 15.0959 8.90411C15.9543 9.76256 16.3836 10.7945 16.3836 12C16.3836 13.2055 15.9543 14.2374 15.0959 15.0959C14.2374 15.9543 13.2055 16.3836 12 16.3836Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmCurrentLocationIcon)
export default ForwardRef
