import * as React from 'react'

const SvgComponent = props => (
  <svg
    width={300}
    height={300}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 2.004 1.333 3.871v6.212H2.5v-3.5c0-.32.114-.595.343-.824.229-.229.503-.343.824-.342h4.666c.321 0 .596.114.825.343.228.228.342.503.342.823v3.5h1.167V3.871L6 2.004ZM3.667 11.25H1.333c-.32 0-.595-.114-.824-.343a1.121 1.121 0 0 1-.342-.824V3.871a1.145 1.145 0 0 1 .729-1.08L5.562.926C5.7.867 5.844.838 6 .838c.156 0 .301.029.437.087l4.667 1.867a1.141 1.141 0 0 1 .73 1.08v6.211c0 .321-.115.596-.344.825a1.121 1.121 0 0 1-.823.342H8.333V6.583H3.667v4.667Zm.583 0v-1.167h1.167v1.167H4.25ZM5.417 9.5V8.333h1.166V9.5H5.417Zm1.166 1.75v-1.167H7.75v1.167H6.583Z"
      fill="#3B4054"
    />
  </svg>
)

export default SvgComponent
