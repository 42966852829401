import {SimpleToastProps} from '@/components/SimpleToast'

/**
 * Success => VmCheckCircleIcon
 * Fail => VmXCircleIcon
 */
const defaultpropssimpletoast: SimpleToastProps = {
  toastervisible: false,
  toastericon: 'VmCheckCircleIcon',
  toastertitle: 'Success',
  toastertype: 'Success',
}
export {defaultpropssimpletoast}
