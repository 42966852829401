import * as React from 'react'
function VmChartLineUpIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M23.0226 22.904C23.335 22.5373 23.5 22.0548 23.5 21.5652C23.5 21.0757 23.335 20.5932 23.0226 20.2264C22.7081 19.8573 22.2628 19.6304 21.7778 19.6304H3.94444V18.521L8.74074 12.8906L10.7531 15.2529C10.7531 15.253 10.7532 15.2531 10.7533 15.2532C10.9087 15.4362 11.0978 15.5866 11.3122 15.6911C11.5271 15.7958 11.7611 15.8513 12 15.8513C12.2389 15.8513 12.4729 15.7958 12.6878 15.6911C12.9022 15.5866 13.0913 15.4362 13.2467 15.2532C13.2468 15.2531 13.2469 15.253 13.2469 15.2529L18.4263 9.17274C18.4356 9.64769 18.5999 10.113 18.9033 10.4692C19.2178 10.8384 19.6631 11.0652 20.1481 11.0652C20.6331 11.0652 21.0785 10.8384 21.393 10.4692C21.7054 10.1025 21.8704 9.61997 21.8704 9.13043V4.34783C21.8704 3.85829 21.7054 3.37579 21.393 3.00905C21.0785 2.63986 20.6331 2.41304 20.1481 2.41304H16.0741C15.5891 2.41304 15.1437 2.63986 14.8292 3.00905C14.5168 3.37579 14.3519 3.85829 14.3519 4.34783C14.3519 4.83736 14.5168 5.31987 14.8292 5.6866C15.1437 6.05579 15.5891 6.28261 16.0741 6.28261H16.1117L12 11.1094L9.98766 8.74707C9.98755 8.74694 9.98744 8.74682 9.98733 8.74669C9.8319 8.56369 9.64287 8.41338 9.42853 8.3089C9.21365 8.20416 8.97964 8.14865 8.74074 8.14865C8.50184 8.14865 8.26783 8.20416 8.05296 8.3089C7.83861 8.41338 7.64958 8.56369 7.49415 8.74669C7.49404 8.74682 7.49393 8.74694 7.49382 8.74707L3.94444 12.9137V2.43478C3.94444 1.94525 3.77949 1.46274 3.46709 1.096C3.15259 0.726817 2.70722 0.5 2.22222 0.5C1.73722 0.5 1.29185 0.726817 0.977359 1.096C0.664953 1.46274 0.5 1.94525 0.5 2.43478V21.5652C0.5 22.0548 0.664953 22.5373 0.977359 22.904C1.29185 23.2732 1.73722 23.5 2.22222 23.5H21.7778C22.2628 23.5 22.7081 23.2732 23.0226 22.904Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmChartLineUpIcon)
export default ForwardRef
