import {Buffer} from "buffer";

const lendata = 3;
const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
const randomId = (length: number) => {
  let result = "";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

const toBinary = (string: string) => {
  const codeUnits = Uint16Array.from(
    {length: string.length},
    (element, index) => string.charCodeAt(index)
  );
  const charCodes = new Uint8Array(codeUnits.buffer);

  let result = "";
  charCodes.forEach((char) => {
    result += String.fromCharCode(char);
  });
  return result;
};

const encode = (str: string, salt = lendata) => {
  const converted = toBinary(`${str}`);
  const encoded = new (Buffer as any).from(converted).toString("base64");
  return `${randomId(salt)}${encoded}=`;
};

const fromBinary = (binary: string) => {
  const bytes = Uint8Array.from({length: binary.length}, (element, index) =>
    binary.charCodeAt(index)
  );
  const charCodes = new Uint16Array(bytes.buffer);

  let result = "";
  charCodes.forEach((char) => {
    result += String.fromCharCode(char);
  });
  return result;
};

const decode = (str = "", salt = lendata) => {
  if (!str) {
    return "";
  }
  const decoded = new (Buffer as any).from(
    str.substring(salt, str.length - 1),
    "base64"
  ).toString("ascii");
  const original = fromBinary(decoded);
  return original;
};

export {toBinary, fromBinary, encode, decode, randomId};
