import * as React from 'react'
function VmImagePlusIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 25 25',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M20.3998 1V4.6ZM20.3998 8.2V4.6ZM20.3998 4.6H23.9998ZM20.3998 4.6H16.7998Z',
      fill: props?.fill || 'currentColor',
    }),
    React.createElement('path', {
      d: 'M20.3998 1V4.6M20.3998 4.6V8.2M20.3998 4.6H23.9998M20.3998 4.6H16.7998',
      stroke: props?.fill || 'currentColor',
      strokeWidth: 1.5,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M13.2 1H3.6C2.64522 1 1.72955 1.37928 1.05442 2.05442C0.379285 2.72955 0 3.64522 0 4.6V17.2C0 17.3032 0.0132001 17.404 0.0384001 17.5C0.0129989 17.598 9.63549e-05 17.6988 0 17.8V21.4C0 22.3548 0.379285 23.2705 1.05442 23.9456C1.72955 24.6207 2.64522 25 3.6 25H20.4C21.3548 25 22.2705 24.6207 22.9456 23.9456C23.6207 23.2705 24 22.3548 24 21.4V13C23.9999 12.8988 23.987 12.798 23.9616 12.7C23.987 12.602 23.9999 12.5012 24 12.4V11.8H21.6V11.8192C16.4436 11.986 12.72 13.228 10.104 14.9668C11.0952 15.3184 12.204 15.8188 13.2912 16.474C14.97 17.482 16.6764 18.898 17.8248 20.7736C17.9071 20.908 17.962 21.0573 17.9866 21.213C18.0112 21.3687 18.0048 21.5276 17.9679 21.6809C17.931 21.8341 17.8643 21.9785 17.7716 22.106C17.6789 22.2334 17.562 22.3413 17.4276 22.4236C17.2932 22.5059 17.1439 22.5608 16.9882 22.5854C16.8325 22.61 16.6736 22.6036 16.5203 22.5667C16.3671 22.5298 16.2227 22.4631 16.0952 22.3704C15.9678 22.2777 15.8599 22.1608 15.7776 22.0264C14.8944 20.5828 13.524 19.4128 12.0552 18.5308C10.5888 17.65 9.0972 17.1004 8.0628 16.8604C6.60566 16.5276 5.11365 16.3721 3.6192 16.3972H3.5928C3.1752 16.4032 2.7768 16.4212 2.4 16.4512V4.6C2.4 4.28174 2.52643 3.97652 2.75147 3.75147C2.97652 3.52643 3.28174 3.4 3.6 3.4H13.2V1ZM7.8 5.8C7.14581 5.80489 6.50867 6.00918 5.9736 6.3856C5.2896 6.8776 4.8 7.6888 4.8 8.8C4.8 9.9112 5.2896 10.7212 5.9736 11.2132C6.50832 11.5905 7.14561 11.7952 7.8 11.8C8.292 11.8 9.0036 11.662 9.6264 11.2132C10.3104 10.7212 10.8 9.9112 10.8 8.8C10.8 7.6888 10.3104 6.8788 9.6264 6.3856C9.09154 6.00876 8.45427 5.80443 7.8 5.8Z',
      fill: props?.fill || 'currentColor',
    })
  )
}
const ForwardRef = React.forwardRef(VmImagePlusIcon)
export default ForwardRef
