import {createElement, forwardRef} from 'react'

function VmCartIcon({title, titleId, ...props}, svgRef) {
  return createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 91 80',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    createElement('path', {
      d: 'M68.8694 71.1137H20.3361C18.1204 71.1137 15.9761 70.3323 14.282 68.9076C12.5879 67.4829 11.4532 65.5066 11.0783 63.328L4.40883 24.5685H84.7964L78.1269 63.328C77.7521 65.5066 76.6175 67.4829 74.9234 68.9076C73.2294 70.3323 71.085 71.1137 68.8694 71.1137Z',
    }),
    createElement('path', {
      d: 'M84.9704 20.8298H4.23504C2.32136 20.8298 0.770004 22.3775 0.770004 24.2867C0.770004 26.1959 2.32136 27.7436 4.23504 27.7436H84.9704C86.8841 27.7436 88.4355 26.1959 88.4355 24.2867C88.4355 22.3775 86.8841 20.8298 84.9704 20.8298Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M84.9713 28.0164H4.23504C3.24365 28.0164 2.29285 27.6235 1.59183 26.9241C0.890803 26.2248 0.496971 25.2762 0.496971 24.2871C0.496971 23.2981 0.890803 22.3495 1.59183 21.6501C2.29285 20.9508 3.24365 20.5579 4.23504 20.5579H84.9713C85.9627 20.5579 86.9135 20.9508 87.6145 21.6501C88.3156 22.3495 88.7094 23.2981 88.7094 24.2871C88.7094 25.2762 88.3156 26.2248 87.6145 26.9241C86.9135 27.6235 85.9627 28.0164 84.9713 28.0164ZM4.23592 21.1031C3.38946 21.1031 2.57767 21.4385 1.97914 22.0357C1.3806 22.6328 1.04435 23.4427 1.04435 24.2871C1.04435 25.1316 1.3806 25.9415 1.97914 26.5386C2.57767 27.1357 3.38946 27.4712 4.23592 27.4712H84.9713C85.8178 27.4712 86.6296 27.1357 87.2281 26.5386C87.8266 25.9415 88.1629 25.1316 88.1629 24.2871C88.1629 23.4427 87.8266 22.6328 87.2281 22.0357C86.6296 21.4385 85.8178 21.1031 84.9713 21.1031H4.23592Z',
    }),
    createElement('path', {
      d: 'M55.2933 22.1653C54.3907 23.2974 54.5789 24.9451 55.7137 25.8456C56.8484 26.7461 58.5001 26.5583 59.4027 25.4262L74.8542 6.04605C75.7568 4.91395 75.5686 3.26622 74.4338 2.36574C73.299 1.46526 71.6474 1.65303 70.7448 2.78513L55.2933 22.1653Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M57.3417 26.6884C56.6879 26.6888 56.0535 26.4669 55.5431 26.0592C54.9422 25.5813 54.5558 24.8851 54.4688 24.1234C54.3818 23.3617 54.6013 22.5966 55.079 21.9961L70.5303 2.61565C70.7673 2.31844 71.0606 2.07071 71.3936 1.88661C71.7265 1.7025 72.0926 1.58561 72.4708 1.54263C72.849 1.49965 73.232 1.53141 73.5979 1.63609C73.9638 1.74078 74.3055 1.91634 74.6034 2.15276C74.9013 2.38918 75.1496 2.68182 75.3342 3.01398C75.5187 3.34614 75.6359 3.71131 75.679 4.08864C75.722 4.46597 75.6902 4.84807 75.5853 5.21313C75.4803 5.57818 75.3044 5.91904 75.0674 6.21625L59.6162 25.5965C59.38 25.8944 59.0868 26.1427 58.7537 26.3269C58.4206 26.511 58.0542 26.6275 57.6757 26.6694C57.5648 26.682 57.4533 26.6884 57.3417 26.6884ZM55.8832 25.6327C56.1249 25.8245 56.4022 25.967 56.6992 26.0519C56.9961 26.1369 57.3069 26.1627 57.6138 26.1278C57.9208 26.0929 58.2178 25.998 58.488 25.8486C58.7581 25.6992 58.9962 25.4981 59.1884 25.2569L74.6396 5.87647C74.8319 5.63531 74.9748 5.35872 75.06 5.06249C75.1451 4.76626 75.171 4.45619 75.1361 4.14998C75.1012 3.84377 75.0061 3.54742 74.8564 3.27786C74.7067 3.00829 74.5052 2.77079 74.2635 2.5789C74.0218 2.38702 73.7445 2.24451 73.4476 2.15952C73.1506 2.07453 72.8398 2.04872 72.5329 2.08356C72.226 2.1184 71.9289 2.21322 71.6587 2.36259C71.3885 2.51196 71.1505 2.71296 70.9581 2.95412L55.5068 22.3343C55.1191 22.8216 54.941 23.4425 55.0116 24.0606C55.0821 24.6787 55.3956 25.2437 55.8832 25.6316V25.6327Z',
    }),
    createElement('path', {
      d: 'M57.1718 25.0035C57.7183 25.0035 58.1612 24.5616 58.1612 24.0165C58.1612 23.4713 57.7183 23.0294 57.1718 23.0294C56.6254 23.0294 56.1825 23.4713 56.1825 24.0165C56.1825 24.5616 56.6254 25.0035 57.1718 25.0035Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M57.1747 25.2763C56.9879 25.2765 56.8034 25.2353 56.6345 25.1558C56.4655 25.0763 56.3163 24.9604 56.1976 24.8165C56.0789 24.6726 55.9936 24.5043 55.9479 24.3236C55.9022 24.1429 55.8972 23.9544 55.9333 23.7715C55.9798 23.5377 56.092 23.3218 56.2567 23.149C56.4214 22.9762 56.6319 22.8536 56.8637 22.7955C57.0955 22.7373 57.3391 22.746 57.5661 22.8206C57.7932 22.8951 57.9943 23.0324 58.1462 23.2165C58.2981 23.4006 58.3945 23.624 58.4241 23.8606C58.4538 24.0972 58.4155 24.3374 58.3138 24.5531C58.212 24.7689 58.0509 24.9514 57.8493 25.0794C57.6476 25.2074 57.4137 25.2757 57.1747 25.2763ZM56.4695 23.8775C56.4372 24.0397 56.4622 24.2081 56.5404 24.3539C56.6186 24.4997 56.745 24.6139 56.8982 24.6772C57.0513 24.7404 57.2217 24.7487 57.3803 24.7006C57.5389 24.6526 57.6758 24.5512 57.7679 24.4136C57.8599 24.2761 57.9013 24.111 57.885 23.9465C57.8687 23.7819 57.7957 23.6281 57.6784 23.5112C57.5611 23.3944 57.4069 23.3217 57.2419 23.3056C57.077 23.2894 56.9115 23.3309 56.7738 23.4228C56.616 23.5282 56.5066 23.6917 56.4695 23.8775Z',
    }),
    createElement('path', {
      d: 'M18.4612 2.78514C17.5586 1.65304 15.907 1.46527 14.7722 2.36575C13.6374 3.26623 13.4492 4.91396 14.3518 6.04606L29.8033 25.4262C30.7059 26.5583 32.3576 26.7461 33.4923 25.8456C34.6271 24.9451 34.8153 23.2974 33.9127 22.1653L18.4612 2.78514Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M31.8609 26.6867C31.4244 26.6875 30.9933 26.5899 30.6 26.4011C30.2066 26.2122 29.8612 25.9371 29.5895 25.5962L14.1379 6.21602C13.6593 5.61578 13.4393 4.85046 13.5263 4.0884C13.6133 3.32635 14.0002 2.63 14.6019 2.15253C15.2035 1.67506 15.9706 1.45559 16.7345 1.5424C17.4983 1.6292 18.1963 2.01518 18.6749 2.61542L34.1265 21.9958C34.466 22.4213 34.6783 22.9336 34.7391 23.474C34.7998 24.0144 34.7065 24.561 34.4699 25.0509C34.2333 25.5408 33.863 25.9541 33.4015 26.2435C32.94 26.5328 32.406 26.6864 31.8609 26.6867ZM16.4041 2.06998C15.9619 2.07031 15.5287 2.19506 15.1543 2.42989C14.7799 2.66473 14.4794 3.00014 14.2875 3.39761C14.0955 3.79509 14.0198 4.23851 14.069 4.67698C14.1182 5.11545 14.2903 5.53118 14.5657 5.87646L30.017 25.2567C30.4058 25.7431 30.9721 26.0559 31.5917 26.1264C32.2113 26.1968 32.8336 26.0192 33.3221 25.6324C33.5638 25.4406 33.7654 25.2032 33.9151 24.9337C34.0649 24.6641 34.16 24.3678 34.195 24.0616C34.2299 23.7555 34.2041 23.4454 34.119 23.1492C34.0338 22.8529 33.891 22.5764 33.6987 22.3352L18.2474 2.95498C18.0269 2.67839 17.7465 2.45512 17.4273 2.30187C17.1081 2.14862 16.7583 2.06934 16.4041 2.06998Z',
    }),
    createElement('path', {
      d: 'M32.0336 25.0035C32.5801 25.0035 33.023 24.5616 33.023 24.0165C33.023 23.4713 32.5801 23.0294 32.0336 23.0294C31.4872 23.0294 31.0443 23.4713 31.0443 24.0165C31.0443 24.5616 31.4872 25.0035 32.0336 25.0035Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M32.031 25.2764C31.7918 25.2759 31.5577 25.2078 31.3559 25.0798C31.154 24.9518 30.9928 24.7693 30.8908 24.5534C30.7889 24.3376 30.7505 24.0973 30.7801 23.8605C30.8097 23.6238 30.9061 23.4003 31.058 23.216C31.21 23.0318 31.4113 22.8943 31.6384 22.8197C31.8656 22.7452 32.1093 22.7364 32.3413 22.7946C32.5732 22.8528 32.7838 22.9756 32.9486 23.1485C33.1134 23.3214 33.2255 23.5375 33.272 23.7716C33.3081 23.9544 33.3031 24.1429 33.2574 24.3236C33.2118 24.5042 33.1265 24.6726 33.0079 24.8165C32.8892 24.9604 32.7401 25.0762 32.5712 25.1557C32.4022 25.2353 32.2178 25.2765 32.031 25.2764ZM32.0352 23.3018C31.8875 23.3015 31.7433 23.3467 31.6224 23.4312C31.5015 23.5158 31.4097 23.6356 31.3597 23.7742C31.3097 23.9128 31.3038 24.0635 31.3429 24.2055C31.3821 24.3476 31.4643 24.4741 31.5783 24.5678C31.6923 24.6614 31.8325 24.7176 31.9797 24.7287C32.127 24.7397 32.2741 24.7051 32.4008 24.6295C32.5276 24.554 32.6279 24.4411 32.6879 24.3065C32.7479 24.1719 32.7647 24.0221 32.736 23.8776C32.7038 23.7156 32.6163 23.5698 32.4886 23.4649C32.3609 23.3599 32.2006 23.3023 32.0352 23.3018Z',
    }),
    createElement('path', {
      d: 'M46.6484 39.9006C46.6484 38.7733 45.7325 37.8595 44.6026 37.8595C43.4728 37.8595 42.5569 38.7733 42.5569 39.9006V59.197C42.5569 60.3243 43.4728 61.2381 44.6026 61.2381C45.7325 61.2381 46.6484 60.3243 46.6484 59.197V39.9006Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M27.0914 39.7018C26.9178 38.5879 25.872 37.8254 24.7556 37.9986C23.6392 38.1717 22.8748 39.2151 23.0484 40.329L26.0202 59.3963C26.1938 60.5102 27.2396 61.2728 28.356 61.0996C29.4724 60.9264 30.2367 59.883 30.0631 58.7692L27.0914 39.7018Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M59.5645 58.769C59.3909 59.8828 60.1553 60.9262 61.2717 61.0994C62.3881 61.2726 63.4339 60.51 63.6075 59.3961L66.5792 40.3288C66.7528 39.2149 65.9885 38.1716 64.8721 37.9984C63.7557 37.8252 62.7099 38.5878 62.5363 39.7016L59.5645 58.769Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M77.9262 79.0187C84.5939 79.0187 89.9991 73.6262 89.9991 66.9743C89.9991 60.3223 84.5939 54.9298 77.9262 54.9298C71.2586 54.9298 65.8534 60.3223 65.8534 66.9743C65.8534 73.6262 71.2586 79.0187 77.9262 79.0187Z',
      fill: 'white',
    }),
    createElement('path', {
      d: 'M77.926 79.2909C75.4842 79.2908 73.0972 78.5684 71.0669 77.2149C69.0366 75.8615 67.4542 73.9378 66.5198 71.6871C65.5854 69.4365 65.341 66.9599 65.8174 64.5707C66.2938 62.1814 67.4697 59.9867 69.1964 58.2642C70.923 56.5416 73.1229 55.3686 75.5178 54.8934C77.9128 54.4182 80.3952 54.6621 82.6511 55.5944C84.907 56.5267 86.8352 58.1054 88.1918 60.131C89.5484 62.1565 90.2724 64.5379 90.2724 66.974C90.2686 70.2396 88.9666 73.3703 86.652 75.6794C84.3374 77.9884 81.1993 79.2872 77.926 79.2909ZM77.926 55.202C75.5923 55.202 73.311 55.8924 71.3706 57.186C69.4302 58.4795 67.9178 60.318 67.0248 62.469C66.1317 64.62 65.898 66.9869 66.3533 69.2704C66.8086 71.5539 67.9324 73.6515 69.5826 75.2978C71.2328 76.9441 73.3353 78.0652 75.6242 78.5195C77.9131 78.9737 80.2856 78.7406 82.4417 77.8496C84.5978 76.9587 86.4406 75.4499 87.7372 73.514C89.0338 71.5782 89.7258 69.3023 89.7259 66.974C89.7223 63.853 88.4779 60.8609 86.2658 58.654C84.0537 56.4472 81.0544 55.2059 77.926 55.2024V55.202Z',
    }),
    createElement('path', {
      d: 'M77.9171 73.1443C77.332 73.1572 76.7536 73.0178 76.2389 72.7399C75.7242 72.462 75.2909 72.0552 74.9817 71.5594C74.2629 70.503 73.9037 68.974 73.904 66.9722C73.9043 64.9705 74.2635 63.4598 74.9817 62.4402C75.3111 61.9674 75.7504 61.5813 76.2621 61.315C76.7737 61.0486 77.3425 60.91 77.9196 60.9109C78.4967 60.9118 79.065 61.0522 79.5758 61.3202C80.0866 61.5881 80.5247 61.9755 80.8527 62.4493C81.5707 63.4752 81.9298 64.9828 81.9301 66.9722C81.9301 68.9738 81.571 70.5029 80.8527 71.5594C80.5433 72.0551 80.11 72.4619 79.5953 72.7398C79.0805 73.0177 78.5022 73.1571 77.9171 73.1443ZM77.9171 71.4764C78.1973 71.4777 78.4718 71.3971 78.7066 71.2445C78.9813 71.0503 79.1967 70.7841 79.3292 70.4753C79.5278 70.0325 79.6652 69.5648 79.7378 69.085C79.8466 68.3862 79.8963 67.6794 79.8864 66.9722C79.8962 66.2742 79.8464 65.5765 79.7378 64.8869C79.6665 64.4158 79.529 63.957 79.3292 63.5243C79.1965 63.2257 78.9803 62.9715 78.7066 62.7922C78.467 62.6526 78.1945 62.5791 77.9171 62.5791C77.6396 62.5791 77.3671 62.6526 77.1275 62.7922C76.8537 62.9713 76.6376 63.2255 76.5051 63.5243C76.3049 63.9569 76.1672 64.4157 76.0963 64.8869C75.9876 65.5765 75.9378 66.2742 75.9477 66.9722C75.9377 67.6792 75.9873 68.3857 76.0961 69.0844C76.1683 69.5642 76.3058 70.032 76.5049 70.4747C76.6371 70.7835 76.8525 71.0498 77.1273 71.2439C77.362 71.3968 77.6367 71.4777 77.9171 71.4764Z',
      strokeWidth: 0,
      fill: props?.stroke || 'black',
    })
  )
}

const ForwardRef = forwardRef(VmCartIcon)
export default ForwardRef
