import React from 'react'
import VmView from '@/components/vmview'
import VmIcons from '../vmicons/VmIcons'

export type SimpleToastProps = {
  toastervisible?: boolean | undefined
  toastericon?: 'VmCheckCircleIcon' | 'VmXCircleIcon' | string | undefined
  toastertitle?: 'Success' | 'Fail' | string | React.ReactElement | undefined
  toastertype?: 'Success' | 'Warning' | 'Info' | 'Fail' | undefined
  toasterdesc?: any
}
/**
 * Success => VmCheckCircleIcon
 * Fail => VmXCircleIcon
 */
export default function SimpleToast({
  toastervisible = false,
  toastericon = 'VmCheckCircleIcon',
  toastertitle = 'Success',
  toastertype = 'Success',
  toasterdesc,
}: SimpleToastProps) {
  if (!toastervisible) {
    return <></>
  }
  const colors = {
    Success: 'text-white bg-green-700',
    Warning: 'text-white bg-orange-700',
    Info: 'text-white bg-sky-600',
    Fail: 'text-white bg-red-700',
  }
  return (
    <VmView
      className={
        'z-50 bg-opacity-95 font-semibold items-center text-sm fixed flex flex-row rounded-full px-3 py-2 top-5 left-5 right-5 ' +
        colors[toastertype]
      }
    >
      <VmIcons
        name={toastericon}
        className="h-6 w-6"
        variant="outline"
        strokeWidth="2.2px"
      />
      <VmView className="ml-2 pt-1">
        {toastertitle || '-'}
        {toasterdesc && <VmView className="text-xs">{toasterdesc}</VmView>}
      </VmView>
    </VmView>
  )
}
