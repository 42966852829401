import * as React from 'react'
function VmArticleIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 24 24',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M21.7778 23H2.22222C1.89807 23 1.58719 22.8841 1.35798 22.6778C1.12877 22.4715 1 22.1917 1 21.9V2.1C1 1.80826 1.12877 1.52847 1.35798 1.32218C1.58719 1.11589 1.89807 1 2.22222 1H21.7778C22.1019 1 22.4128 1.11589 22.642 1.32218C22.8712 1.52847 23 1.80826 23 2.1V21.9C23 22.1917 22.8712 22.4715 22.642 22.6778C22.4128 22.8841 22.1019 23 21.7778 23Z',
    }),
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: 0.1,
      fill: props?.stroke || 'currentColor',
      d: 'M5.88889 5.4H10.7778V9.8H5.88889V5.4ZM5.88889 12H18.1111V14.2H5.88889V12ZM5.88889 16.4H18.1111V18.6H5.88889V16.4ZM13.2222 6.5H18.1111V8.7H13.2222V6.5Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmArticleIcon)
export default ForwardRef
