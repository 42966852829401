const BITESHIP_KEY = (): string | undefined =>
  process.env.NEXT_PUBLIC_BITESHIP_KEY
const BITESHIP_BASE: string | undefined = process.env.NEXT_PUBLIC_BITESHIP_BASE
const URL_VMART: string | undefined = process.env.NEXT_PUBLIC_URL_VMART
const URL_VWEB5: string | undefined = process.env.NEXT_PUBLIC_URL_VWEB5
const URL_VWEB7: string | undefined = process.env.NEXT_PUBLIC_URL_VWEB7
const MAPS_API_KEY: string | undefined = process.env.NEXT_PUBLIC_G_MAPS_API_KEY
const STAGE: string | undefined = process.env.NEXT_PUBLIC_STAGE
const S3_FOLDER: string | undefined = process.env.NEXT_PUBLIC_AWS_S3_FOLDER
const TIMEOUT_QRIS_API_CALLBACK: string | undefined =
  process.env.NEXT_PUBLIC_TIMEOUT_QRIS_API_CALLBACK

const showlog = process.env.NEXT_PUBLIC_ENV === 'development'
const CART_VARIABLES = {
  CartTotalItems: 'cti',
  CartItems: 'ci',
  CartTotal: 'ct',
  CartCourier: 'cc',
  CartPayments: 'cp',
  CartPoin: 'cpn',
  ChangeAddress: 'ca',
  CartOverview: 'co',
  CartStgCost: 'sc',
  CartPayQris: 'cpq',
  CartPayVa: 'cpv',
  SelectedAddress: 'sa',
}
const QRIS_VARIABLES = {
  timeout: Number(TIMEOUT_QRIS_API_CALLBACK || 5000),
}
const VA_VARIABLES = {
  timeout: Number(TIMEOUT_QRIS_API_CALLBACK || 5000),
}
const USER_VARIABLES = {
  DataAuth: 'user',
  GeoPosition: 'up',
}
const APP_VARIABLES = {
  Pharmacys: 'cabang_aktif',
  SimpleToast: 'ts',
  AppLoading: 'al',
  NoPictProduct: 'https://apipasien.vmedis.com/foto/nopict.png',
  Stage: STAGE,
  S3FOLDER: S3_FOLDER,
}
const STRG_OPT = showlog ? {type: 0} : {type: 2}
export {
  BITESHIP_KEY,
  BITESHIP_BASE,
  URL_VMART,
  URL_VWEB5,
  URL_VWEB7,
  CART_VARIABLES,
  USER_VARIABLES,
  APP_VARIABLES,
  QRIS_VARIABLES,
  VA_VARIABLES,
  STRG_OPT,
  MAPS_API_KEY,
  showlog,
}
