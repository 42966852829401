import * as React from 'react'

function VmWave0Icon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 1440 253',
        strokeWidth: 0,
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M-480 118.089L-453.333 113.684C-426.667 109.28 -373.333 99.8413 -320 104.246C-266.667 109.28 -213.333 126.898 -160 140.741C-106.667 154.583 -53.3333 163.392 0 140.741C53.3334 118.089 106.667 63.9758 160 31.8856C213.333 0.424598 266.667 -9.01369 320 9.23367C373.333 27.481 426.667 72.7848 480 91.0322C533.333 109.28 586.667 99.8413 640 99.8413C693.333 99.8413 746.667 109.28 800 113.684C853.333 118.089 906.667 118.089 960 136.336C1013.33 154.583 1066.67 190.449 1120 167.797C1173.33 145.145 1226.67 63.9758 1280 41.3239C1333.33 18.672 1386.67 54.5375 1413.33 72.7848L1440 91.0322V254H1413.33C1386.67 254 1333.33 254 1280 254C1226.67 254 1173.33 254 1120 254C1066.67 254 1013.33 254 960 254C906.667 254 853.333 254 800 254C746.667 254 693.333 254 640 254C586.667 254 533.333 254 480 254C426.667 254 373.333 254 320 254C266.667 254 213.333 254 160 254C106.667 254 53.3334 254 0 254C-53.3333 254 -106.667 254 -160 254C-213.333 254 -266.667 254 -320 254C-373.333 254 -426.667 254 -453.333 254H-480V118.089Z',
      fill: 'url(#paint0_linear_255_8920)',
      fillOpacity: '0.2',
    }),
    React.createElement(
      'defs',
      {},
      React.createElement(
        'linearGradient',
        {
          gradientUnits: 'userSpaceOnUse',
          id: 'paint0_linear_255_8920',
          x1: '480',
          y1: '0',
          x2: '480',
          y2: '254',
        },
        React.createElement('stop', {stopColor: props?.color || 'white'}),
        React.createElement('stop', {
          stopColor: props?.color || 'white',
          offset: '1',
          stopOpacity: '0',
        })
      )
    )
  )
}

const ForwardRef = React.forwardRef(VmWave0Icon)
export default ForwardRef
