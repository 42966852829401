import * as React from 'react'
function VmMoneyIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 22',
        fill: 'currentColor',
        'aria-hidden': 'true',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M22.0645 0H1.93548C1.42295 0.00380433 0.932124 0.310006 0.569701 0.852052C0.207277 1.3941 0.00254366 2.12818 0 2.89474V19.1053C0.00254366 19.8718 0.207277 20.6059 0.569701 21.1479C0.932124 21.69 1.42295 21.9962 1.93548 22H22.0645C22.5771 21.9962 23.0679 21.69 23.4303 21.1479C23.7927 20.6059 23.9975 19.8718 24 19.1053V2.89474C23.9975 2.12818 23.7927 1.3941 23.4303 0.852052C23.0679 0.310006 22.5771 0.00380433 22.0645 0ZM8.57419 18.5263L2.9129 11L8.57419 3.47368H15.4258L21.0871 11L15.4258 18.5263H8.57419ZM21.6774 7.13553L18.929 3.47368H21.6774V7.13553ZM5.07097 3.47368L2.32258 7.13553V3.47368H5.07097ZM2.32258 14.8645L5.07097 18.5263H2.32258V14.8645ZM18.929 18.5263L21.6774 14.8645V18.5263H18.929ZM12 5.21053C11.2344 5.21053 10.486 5.55007 9.84941 6.18623C9.21283 6.82238 8.71668 7.72658 8.42369 8.78446C8.13071 9.84235 8.05405 11.0064 8.20341 12.1295C8.35277 13.2525 8.72145 14.2841 9.26281 15.0938C9.80418 15.9034 10.4939 16.4548 11.2448 16.6782C11.9957 16.9016 12.774 16.787 13.4814 16.3488C14.1887 15.9106 14.7932 15.1685 15.2186 14.2165C15.6439 13.2644 15.871 12.145 15.871 11C15.871 9.46454 15.4631 7.99196 14.7372 6.90622C14.0112 5.82049 13.0266 5.21053 12 5.21053ZM12 13.3158C11.6938 13.3158 11.3944 13.18 11.1398 12.9255C10.8851 12.671 10.6867 12.3094 10.5695 11.8862C10.4523 11.4631 10.4216 10.9974 10.4814 10.5482C10.5411 10.099 10.6886 9.68636 10.9051 9.36249C11.1217 9.03862 11.3976 8.81806 11.6979 8.72871C11.9983 8.63935 12.3096 8.68521 12.5925 8.86049C12.8755 9.03577 13.1173 9.33259 13.2874 9.71342C13.4576 10.0942 13.5484 10.542 13.5484 11C13.5484 11.6142 13.3853 12.2032 13.0949 12.6375C12.8045 13.0718 12.4107 13.3158 12 13.3158Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmMoneyIcon)
export default ForwardRef
