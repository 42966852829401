import * as React from 'react'
function VmDeleteBinIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 26 26',
        fill: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      'aria-label': props?.['aria-label'],
      d: 'M7.875 5.125C7.875 4.03098 8.3096 2.98177 9.08318 2.20818C9.85677 1.4346 10.906 1 12 1C13.094 1 14.1432 1.4346 14.9168 2.20818C15.6904 2.98177 16.125 4.03098 16.125 5.125M7.875 5.125H16.125ZM7.875 5.125H3.75ZM16.125 5.125H20.25ZM23 5.125H20.25ZM1 5.125H3.75ZM3.75 5.125V20.25C3.75 20.9793 4.03973 21.6788 4.55546 22.1945C5.07118 22.7103 5.77065 23 6.5 23H17.5C18.2293 23 18.9288 22.7103 19.4445 22.1945C19.9603 21.6788 20.25 20.9793 20.25 20.25V5.125',
    }),
    React.createElement('path', {
      'aria-label': props?.['aria-label'],
      d: 'M7.875 5.125C7.875 4.03098 8.3096 2.98177 9.08318 2.20818C9.85677 1.4346 10.906 1 12 1C13.094 1 14.1432 1.4346 14.9168 2.20818C15.6904 2.98177 16.125 4.03098 16.125 5.125M7.875 5.125H16.125M7.875 5.125H3.75M16.125 5.125H20.25M3.75 5.125H1M3.75 5.125V20.25C3.75 20.9793 4.03973 21.6788 4.55546 22.1945C5.07118 22.7103 5.77065 23 6.5 23H17.5C18.2293 23 18.9288 22.7103 19.4445 22.1945C19.9603 21.6788 20.25 20.9793 20.25 20.25V5.125M20.25 5.125H23',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: 1.5,
      stroke: props?.fill || 'currentColor',
    })
  )
}
const ForwardRef = React.forwardRef(VmDeleteBinIcon)
export default ForwardRef
