const VmExcelIcon = ({
  className = '',
  title = '',
  //   titleId = '',
  svgRef,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 18 18"
    aria-hidden="true"
    {...{className, ref: svgRef, ...props}}
  >
    <path d="M14.625.563h-10a1.562 1.562 0 0 0-1.563 1.562v1.25h-1.25A1.562 1.562 0 0 0 .25 4.938v8.125a1.563 1.563 0 0 0 1.563 1.562h1.25v1.25a1.563 1.563 0 0 0 1.562 1.563h10a1.563 1.563 0 0 0 1.563-1.563V2.125A1.563 1.563 0 0 0 14.624.562Zm-2.5 6.875h2.188v3.125h-2.188V7.437Zm2.188-1.875h-2.188v-.625a1.563 1.563 0 0 0-1.563-1.563v-.938h3.75v3.126ZM4.937 2.438h3.75v.937h-3.75v-.938ZM2.126 5.25h8.125v7.5H2.125v-7.5Zm2.813 9.375h3.75v.938h-3.75v-.938Zm5.625.938v-.938a1.563 1.563 0 0 0 1.562-1.563v-.624h2.188v3.124h-3.75Zm-6.538-3.968a.937.937 0 0 1-.12-1.32L4.967 9 3.905 7.725a.937.937 0 1 1 1.44-1.2l.843 1.01.842-1.01a.938.938 0 0 1 1.44 1.2L7.408 9l1.062 1.275a.937.937 0 1 1-1.44 1.2l-.843-1.01-.842 1.01a.938.938 0 0 1-1.32.12Z" />
  </svg>
)
export default VmExcelIcon
