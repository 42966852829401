import {createElement, forwardRef} from 'react'

function VmSlimeIcon({title, titleId, ...props}, svgRef) {
  return createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 122 101',
        strokeWidth: 0,
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    createElement('path', {
      d: 'M121.441 68.778C118.108 52.0146 98.5865 38.6167 94.7789 21.148C87.8587 -1.07692 65.049 -4.59072 50.2268 5.06155C26.741 20.3557 51.7955 24.151 5.74743 60.3361C-5.62287 69.2709 -1.99978 108.711 33.7705 99.6598C53.0205 94.7887 58.8725 94.1052 67.5312 93.3031C89.3559 91.2816 127.034 96.9174 121.441 68.778Z',
      fill: props?.color || '#0084CF',
      opacity: '0.1',
    })
  )
}

const ForwardRef = forwardRef(VmSlimeIcon)
export default ForwardRef
