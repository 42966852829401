import * as React from 'react'
function VmImagePlusIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M19.6999 1V4.3M19.6999 4.3V7.6M19.6999 4.3H22.9999M19.6999 4.3H16.3999',
      strokeWidth: 1.5,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    }),
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M13.1 1H4.3C3.42479 1 2.58542 1.34768 1.96655 1.96655C1.34768 2.58542 1 3.42479 1 4.3V15.85C1 15.9446 1.0121 16.037 1.0352 16.125C1.01192 16.2148 1.00009 16.3072 1 16.4V19.7C1 20.5752 1.34768 21.4146 1.96655 22.0335C2.58542 22.6523 3.42479 23 4.3 23H19.7C20.5752 23 21.4146 22.6523 22.0335 22.0335C22.6523 21.4146 23 20.5752 23 19.7V12C22.9999 11.9072 22.9881 11.8148 22.9648 11.725C22.9881 11.6352 22.9999 11.5428 23 11.45V10.9H20.8V10.9176C16.0733 11.0705 12.66 12.209 10.262 13.8029C11.1706 14.1252 12.187 14.5839 13.1836 15.1845C14.7225 16.1085 16.2867 17.4065 17.3394 19.1258C17.4148 19.249 17.4652 19.3859 17.4877 19.5286C17.5102 19.6713 17.5044 19.817 17.4706 19.9575C17.4368 20.0979 17.3756 20.2303 17.2907 20.3471C17.2057 20.464 17.0985 20.5629 16.9753 20.6383C16.8521 20.7137 16.7152 20.7641 16.5725 20.7866C16.4298 20.8091 16.2841 20.8033 16.1436 20.7695C16.0032 20.7357 15.8708 20.6745 15.754 20.5896C15.6371 20.5046 15.5382 20.3974 15.4628 20.2742C14.6532 18.9509 13.397 17.8784 12.0506 17.0699C10.7064 16.2625 9.3391 15.7587 8.3909 15.5387C7.05518 15.2337 5.68751 15.0911 4.3176 15.1141H4.2934C3.9106 15.1196 3.5454 15.1361 3.2 15.1636V4.3C3.2 4.00826 3.31589 3.72847 3.52218 3.52218C3.72847 3.31589 4.00826 3.2 4.3 3.2H13.1V1ZM8.15 5.4C7.55032 5.40448 6.96628 5.59175 6.4758 5.9368C5.8488 6.3878 5.4 7.1314 5.4 8.15C5.4 9.1686 5.8488 9.9111 6.4758 10.3621C6.96596 10.7079 7.55014 10.8956 8.15 10.9C8.601 10.9 9.2533 10.7735 9.8242 10.3621C10.4512 9.9111 10.9 9.1686 10.9 8.15C10.9 7.1314 10.4512 6.3889 9.8242 5.9368C9.33391 5.59137 8.74974 5.40406 8.15 5.4Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmImagePlusIcon)
export default ForwardRef
