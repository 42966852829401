import {decode, encode} from '../utilities/encryptions'

interface LocalStorageOptions {
  type: number
}
/**
 *
 * ==================== setLclStorage =====================
 * = type 1     => set with enc 1                         =
 * = type 2     => set with enc 2                         =
 * ========================================================
 * @param {String} name
 * @param {*} value
 * @param {Object} options
 * @returns new name
 */
const setLclStorage: (
  name: string,
  value: string,
  options?: LocalStorageOptions
) => string = (name, value, options) => {
  if (typeof localStorage === 'undefined') {
    return name
  }
  const type = options?.type
  if (type === 1) {
    name = encode(name)
    localStorage.setItem(name, value)
    return name
  }
  if (type === 2) {
    value = encode(value)
    localStorage.setItem(name, value)
    return name
  }
  localStorage.setItem(name, value)
  return name
}

/**
 *
 * ==================== getLclStorage =====================
 * = type 1     => get with enc 1                         =
 * = type 2     => get with enc 2                         =
 * ========================================================
 * @param {String} name
 * @param {Object} options
 * @returns new value
 */
const getLclStorage: (
  name: string,
  options?: LocalStorageOptions
) => string | null = (name, options) => {
  if (typeof localStorage === 'undefined') {
    return null
  }
  const type = options?.type
  if (type === 1) {
    name = decode(name)
    return localStorage.getItem(name)
  }
  if (type === 2) {
    return decode(localStorage.getItem(name) || '')
  }
  return localStorage.getItem(name)
}

/**
 *
 * ==================== removeLclStorage =====================
 * = type 1     => remove with dec 1                         =
 * ===========================================================
 * @param {String} name
 * @param {Object} options
 * @returns new value
 */
const removeLclStorage = (
  name: string,
  options?: LocalStorageOptions
): void => {
  if (typeof localStorage === 'undefined') {
    return
  }
  const type = options?.type
  if (type === 1) {
    name = decode(name)
    return localStorage.removeItem(name)
  }
  if (type === 2) {
    return localStorage.removeItem(name)
  }
  return localStorage.removeItem(name)
}

export {setLclStorage, getLclStorage, removeLclStorage}
