import * as React from 'react'
function VmArticleIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M22.6667 24H1.33333C0.979711 24 0.640573 23.8736 0.390524 23.6485C0.140476 23.4235 0 23.1183 0 22.8V1.2C0 0.88174 0.140476 0.576515 0.390524 0.351472C0.640573 0.126428 0.979711 0 1.33333 0H22.6667C23.0203 0 23.3594 0.126428 23.6095 0.351472C23.8595 0.576515 24 0.88174 24 1.2V22.8C24 23.1183 23.8595 23.4235 23.6095 23.6485C23.3594 23.8736 23.0203 24 22.6667 24ZM5.33333 4.8H10.6667V9.6H5.33333V4.8ZM5.33333 12H18.6667V14.4H5.33333V12ZM5.33333 16.8H18.6667V19.2H5.33333V16.8ZM13.3333 6H18.6667V8.4H13.3333V6Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmArticleIcon)
export default ForwardRef
