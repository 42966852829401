import React, {AriaAttributes} from 'react'
import * as Icons from '@/components/icons/solid'
import * as OutlineIcons from '@/components/icons/outline'
import * as AvatarIcons from '@/components/icons/avatar'
import * as AbstractIcons from '@/components/icons/abstract'

const iconNames = Object.keys(Icons)
const iconOutlineNames = Object.keys(OutlineIcons)
const iconAvatarNames = Object.keys(AvatarIcons)
const iconAbstractNames = Object.keys(AbstractIcons)
type iconTypes = keyof typeof Icons
type iconOutlineTypes = keyof typeof OutlineIcons
type iconAvatarTypes = keyof typeof AvatarIcons
type iconAbstractTypes = keyof typeof AbstractIcons
type VmIconsTypes = {
  title?: string
  titleId?: string
  name?:
    | iconTypes
    | iconOutlineTypes
    | iconAvatarTypes
    | iconAbstractTypes
    | string
    | undefined
  label?: string | undefined
  strokeWidth?: string
  size?: number | string
  usesizepx?: boolean | undefined
  color?: string
  style?: CSSStyleDeclaration
  variant?: 'solid' | 'outline' | 'avatar' | 'abstract' | string
  className?: string
} & AriaAttributes
const VmIcons = (props: VmIconsTypes) => {
  const {
    title = null,
    titleId = null,
    label = null,
    usesizepx = true,
    name = null,
    size = 12,
    color = 'currentColor',
    variant = 'solid',
    style,
  } = props
  const newattributes = props
  if (newattributes?.size) {
    delete newattributes?.size
  }
  if (!variant) {
    throw new TypeError(`${variant} is not valid icon variant`)
  }
  if (!name) {
    throw new TypeError(`${name} is not valid icon name`)
  }
  const IconVariant: {[key: string]: any} = {
    solid: Icons,
    outline: OutlineIcons,
    avatar: AvatarIcons,
    abstract: AbstractIcons,
  }
  if (variant === 'avatar') {
    const VmIcon = IconVariant[variant][name]
    if (!VmIcon) {
      throw new TypeError(`${name} is not valid icon name or type`)
    }
    return (
      <VmIcon
        {...Object.assign(newattributes, label ? {'aria-label': label} : {})}
        height={usesizepx ? `${size}px` : size}
        width={usesizepx ? `${size}px` : size}
        title={title}
        titleId={titleId}
        style={style}
      />
    )
  }
  if (variant === 'abstract') {
    const VmIcon = IconVariant[variant][name]
    if (!VmIcon) {
      throw new TypeError(`${name} is not valid icon name or type`)
    }
    return (
      <VmIcon
        {...Object.assign(newattributes, label ? {'aria-label': label} : {})}
        height={usesizepx ? `${size}px` : size}
        width={usesizepx ? `${size}px` : size}
        title={title}
        titleId={titleId}
        style={style}
        color={color}
      />
    )
  }
  const VmIcon = IconVariant[variant][name]
  return (
    <VmIcon
      stroke={variant === 'outline' ? color : '#00000000'}
      fill={variant === 'outline' ? '#00000000' : color}
      {...Object.assign(newattributes, label ? {'aria-label': label} : {})}
      height={usesizepx ? `${size}px` : size}
      width={usesizepx ? `${size}px` : size}
      title={title}
      titleId={titleId}
      style={style}
    />
  )
}

export {iconNames, iconOutlineNames, iconAvatarNames, iconAbstractNames}
export default VmIcons
