import * as React from 'react'

function VmWave1Icon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 1440 253',
        strokeWidth: 0,
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M-20 118.089L4.01389 113.684C28.0278 109.28 76.0556 99.8413 124.083 104.246C172.111 109.28 220.139 126.898 268.167 140.741C316.194 154.583 364.222 163.392 412.25 140.741C460.278 118.089 508.306 63.9758 556.333 31.8856C604.361 0.424597 652.389 -9.01369 700.417 9.23367C748.444 27.481 796.472 72.7848 844.5 91.0322C892.528 109.28 940.556 99.8413 988.583 99.8413C1036.61 99.8413 1084.64 109.28 1132.67 113.684C1180.69 118.089 1228.72 118.089 1276.75 136.336C1324.78 154.583 1372.81 190.449 1420.83 167.797C1468.86 145.145 1516.89 63.9758 1564.92 41.3239C1612.94 18.672 1660.97 54.5375 1684.99 72.7848L1709 91.0322V254H1684.99C1660.97 254 1612.94 254 1564.92 254C1516.89 254 1468.86 254 1420.83 254C1372.81 254 1324.78 254 1276.75 254C1228.72 254 1180.69 254 1132.67 254C1084.64 254 1036.61 254 988.583 254C940.556 254 892.528 254 844.5 254C796.472 254 748.444 254 700.417 254C652.389 254 604.361 254 556.333 254C508.306 254 460.278 254 412.25 254C364.222 254 316.194 254 268.167 254C220.139 254 172.111 254 124.083 254C76.0556 254 28.0278 254 4.01389 254H-20V118.089Z',
      fill: 'url(#paint0_linear_255_8919)',
      fillOpacity: '0.2',
    }),
    React.createElement(
      'defs',
      {},
      React.createElement(
        'linearGradient',
        {
          gradientUnits: 'userSpaceOnUse',
          id: 'paint0_linear_255_8919',
          x1: '844.5',
          y1: '0',
          x2: '844.5',
          y2: '254',
        },
        React.createElement('stop', {stopColor: props?.color || 'white'}),
        React.createElement('stop', {
          stopColor: props?.color || 'white',
          offset: '1',
          stopOpacity: '0',
        })
      )
    )
  )
}

const ForwardRef = React.forwardRef(VmWave1Icon)
export default ForwardRef
