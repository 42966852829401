import * as React from 'react'
function VmHomePlusIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        'aria-hidden': 'true',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M9.9375 14.8286V16.0857C9.9375 16.6095 10.1382 17.055 10.5397 17.4221C10.9412 17.7891 11.428 17.9723 12 17.9714C12.5729 17.9714 13.0601 17.7879 13.4616 17.4208C13.8631 17.0537 14.0634 16.6087 14.0625 16.0857V14.8286H15.4375C16.0104 14.8286 16.4976 14.645 16.8991 14.2779C17.3006 13.9109 17.5009 13.4658 17.5 12.9429C17.5 12.419 17.2992 11.9736 16.8977 11.6065C16.4962 11.2394 16.0095 11.0563 15.4375 11.0571H14.0625V9.8C14.0625 9.27619 13.8617 8.83074 13.4602 8.46366C13.0587 8.09657 12.572 7.91345 12 7.91428C11.4271 7.91428 10.9399 8.09783 10.5384 8.46491C10.1369 8.832 9.93658 9.27703 9.9375 9.8V11.0571H8.5625C7.98958 11.0571 7.50238 11.2407 7.10088 11.6078C6.69938 11.9749 6.49908 12.4199 6.5 12.9429C6.5 13.4667 6.70075 13.9121 7.10225 14.2792C7.50375 14.6463 7.9905 14.8294 8.5625 14.8286H9.9375ZM3.75 23C2.99375 23 2.34613 22.7536 1.80713 22.2608C1.26813 21.768 0.999086 21.1763 1 20.4857V9.17143C1 8.77333 1.09763 8.39619 1.29288 8.04C1.48813 7.68381 1.75717 7.39047 2.1 7.16L10.35 1.50286C10.8312 1.16762 11.3812 1 12 1C12.6187 1 13.1687 1.16762 13.65 1.50286L21.9 7.16C22.2437 7.39047 22.5132 7.68381 22.7085 8.04C22.9037 8.39619 23.0009 8.77333 23 9.17143V20.4857C23 21.1771 22.7305 21.7693 22.1915 22.2621C21.6525 22.7549 21.0053 23.0008 20.25 23H3.75Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmHomePlusIcon)
export default ForwardRef
