import * as React from 'react'
function VmDrugsIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M16.9064 1.8C15.7241 0.6 14.1872 0 12.7685 0C11.3498 0 9.69458 0.6 8.63054 1.8L1.7734 8.52C-0.591133 10.92 -0.591133 14.64 1.7734 17.04C4.13793 19.44 7.80296 19.44 10.1675 17.04L16.9064 10.2C19.1527 7.92 19.1527 4.08 16.9064 1.8ZM15.2512 8.52L11.9409 11.88L7.68473 7.68L2.48276 12.96C2.48276 12 2.71921 10.92 3.5468 10.2L10.2857 3.36C10.8768 2.76 11.8227 2.4 12.6502 2.4C13.4778 2.4 14.4236 2.76 15.133 3.36C16.5517 4.92 16.5517 7.08 15.2512 8.52ZM20.9261 6.12C20.9261 7.08 20.6897 7.92 20.4532 8.88C21.6355 10.32 21.6355 12.48 20.335 13.8L17.0246 17.16L15.2512 15.36L11.9409 18.72C10.4039 20.28 8.27586 21.12 6.26601 21.12C6.50246 21.48 6.73892 21.84 7.0936 22.2C9.45813 24.6 13.1232 24.6 15.4877 22.2L22.2266 15.36C24.5911 12.96 24.5911 9.24 22.2266 6.84C21.6355 6.6 21.2808 6.36 20.9261 6.12Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmDrugsIcon)
export default ForwardRef
