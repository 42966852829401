import React, { useEffect, useMemo, useState } from 'react'
import Head from 'next/head'
import { useLoadScript } from '@react-google-maps/api'
import type { AppProps } from 'next/app'
import '@/styles/globals.scss'

import { NextUIProvider, Spinner } from '@nextui-org/react'
import VmView from '@/components/vmview'
import { Amplify } from 'aws-amplify'
import SimpleToast, { SimpleToastProps } from '@/components/SimpleToast'
import { defaultpropssimpletoast } from '@/entries/components/simpletoast'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from '@/utils/localstorage'
import {
  APP_VARIABLES,
  MAPS_API_KEY,
  STRG_OPT,
  USER_VARIABLES,
} from '@/utilities/core'
import VmSpinnerFullScreen from '@/components/vmspinner/vmspinnerfullscreen'

let config = {
  Auth: {
    identityPoolId: process.env.NEXT_PUBLIC_AWS_IDENTITY_POOL_ID, //REQUIRED - Amazon Cognito Identity Pool ID
    region: process.env.NEXT_PUBLIC_AWS_S3_REGION, // REQUIRED - Amazon Cognito Region
    userPoolId: process.env.NEXT_PUBLIC_AWS_USER_POOL_ID, //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_USER_POOL_WEBCLIENT_ID, //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: process.env.NEXT_PUBLIC_AWS_S3_BUCKET, //REQUIRED -  Amazon S3 bucket
      region: process.env.NEXT_PUBLIC_AWS_S3_REGION, //OPTIONAL -  Amazon service region
    },
  },
}

Amplify.configure(config)

type ComponentProps = {
  Component: {
    title: string | undefined
    layout: Node | any
  }
}
type VmAppProps = AppProps & ComponentProps
export default function App({ Component, pageProps }: VmAppProps) {
  const getLoading = () => {
    const strgloading =
      getLclStorage(APP_VARIABLES.AppLoading, STRG_OPT) === 'true'
    return strgloading
  }
  const [loading, setloading] = useState(false)
  const [currentPosition, setCurrentPosition] = useState({})
  const [geoAccess, setgeoAccess] = useState<boolean | null>(null)
  const [propssimpletoast, setpropssimpletoast] = useState<SimpleToastProps>(
    defaultpropssimpletoast
  )
  const propsGMaps: any = useMemo(() => {
    return {
      googleMapsApiKey: MAPS_API_KEY as string,
      libraries: ['places'],
      language: 'id',
      region: 'ID',
    }
  }, [MAPS_API_KEY]) /* eslint-disable-line */
  const { isLoaded } = useLoadScript(propsGMaps)
  const getCurrentLoc = () => {
    if (typeof window === 'undefined') {
      return
    }
    const newwindow: { [key: string]: any } | { ReactNativeWebView?: any } = window
    if (newwindow?.ReactNativeWebView?.postMessage) {
      return newwindow.ReactNativeWebView.postMessage('onGetCurrentPosition:')
    }
    if (navigator?.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude, longitude } = position.coords
          setCurrentPosition({
            lat: latitude,
            lng: longitude,
            latLng: {
              lat: latitude,
              lng: longitude,
            },
          })
          setgeoAccess(true)
        },
        error => {
          onShowToast({
            message: <>Gagal mengambil lokasi anda!</>,
            type: 'Fail',
          })
          if (error?.PERMISSION_DENIED) {
            setgeoAccess(false)
          }
        }
      )
    } else {
      onShowToast({
        message: <>Lokasi terkini tidak support pada device ini!</>,
        type: 'Fail',
      })
    }
  }
  const onShowToast = ({
    message = '-',
    type = 'Success',
    descriptions = null,
  }: {
    message?: SimpleToastProps['toastertitle']
    type?: SimpleToastProps['toastertype']
    descriptions?: SimpleToastProps['toasterdesc']
  }) => {
    var newtimeout: any = null
    newtimeout = setTimeout(() => {
      setpropssimpletoast(defaultpropssimpletoast)
      clearTimeout(newtimeout)
    }, 3000)
    var toastericon = 'VmCheckCircleIcon'
    if (type === 'Fail') {
      toastericon = 'VmXCircleIcon'
    }
    if (type === 'Warning') {
      toastericon = 'VmExclamationCircleIcon'
    }
    if (type === 'Info') {
      toastericon = 'VmInformationCircleIcon'
    }
    return setpropssimpletoast({
      toastericon,
      toastertitle: message,
      toastervisible: true,
      toastertype: type,
      toasterdesc: descriptions,
    })
  }
  const listenSimpleToast = () => {
    var toastData: any = getLclStorage(APP_VARIABLES.SimpleToast, STRG_OPT)
    if (toastData === null) {
      toastData = { length: false }
    }
    var newtimeout: any = null
    newtimeout = setTimeout(() => {
      if (toastData?.length) {
        toastData = JSON.parse(toastData)
        if (typeof toastData?.message === 'string') {
          onShowToast(toastData)
          removeLclStorage(APP_VARIABLES.SimpleToast, STRG_OPT)
        }
      }
      setloading(getLoading())
      clearTimeout(newtimeout)
      listenSimpleToast()
    }, 500)
  }
  useMemo(() => {
    listenSimpleToast()
  }, []) /* eslint-disable-line */
  useEffect(() => {
    getCurrentLoc()
    return () => {
      setLclStorage(APP_VARIABLES.AppLoading, '', STRG_OPT)
    }
  }, []) /* eslint-disable-line */
  useEffect(() => {
    setLclStorage(
      USER_VARIABLES.GeoPosition,
      JSON.stringify({ currentPosition, geoAccess }),
      STRG_OPT
    )
    return () => { }
  }, [currentPosition, geoAccess]) /* eslint-disable-line */

  const Layout =
    Component?.layout || (({ children = '' }): any => <>{children}</>)
  const Title = Component?.title || (() => <title>Vmedis</title>)

  return (
    <>
      <Head>
        <Title />
      </Head>
      <NextUIProvider>
        {isLoaded ? (
          <Layout>
            <Component {...pageProps} />
          </Layout>
        ) : (
          <VmView className="fixed inset-0 flex justify-center items-center">
            <Spinner />
          </VmView>
        )}
        <SimpleToast
          key={JSON.stringify(propssimpletoast)}
          {...propssimpletoast}
        />
        <VmSpinnerFullScreen visible={loading} />
      </NextUIProvider>
    </>
  )
}
