import * as React from 'react'
function VmChartBarFloatingIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M10.5885 1.58971L10.5897 1.5885C10.9906 1.18691 11.4426 1 12 1C12.5574 1 13.0103 1.18697 13.4124 1.58911C13.8132 1.98989 14 2.44207 14 3V21C14 21.5577 13.8133 22.0105 13.4124 22.4124C13.0105 22.8133 12.5577 23 12 23C11.4421 23 10.9899 22.8132 10.5891 22.4124C10.187 22.0103 10 21.5574 10 21V3C10 2.44262 10.1869 1.99062 10.5885 1.58971ZM3 23C2.44207 23 1.98989 22.8132 1.58911 22.4124C1.18697 22.0103 1 21.5574 1 21V10.5C1 9.94258 1.18697 9.48975 1.58911 9.08761C1.98989 8.68683 2.44207 8.5 3 8.5C3.55793 8.5 4.01011 8.68683 4.41089 9.08761C4.81303 9.48975 5 9.94258 5 10.5V21C5 21.5574 4.81303 22.0103 4.41089 22.4124C4.01011 22.8132 3.55793 23 3 23ZM21 23C20.4423 23 19.9895 22.8133 19.5876 22.4124C19.1867 22.0105 19 21.5577 19 21V16.5C19 15.9423 19.1867 15.4895 19.5876 15.0876C19.9895 14.6867 20.4423 14.5 21 14.5C21.5577 14.5 22.0105 14.6867 22.4124 15.0876C22.8133 15.4895 23 15.9423 23 16.5V21C23 21.5577 22.8133 22.0105 22.4124 22.4124C22.0105 22.8133 21.5577 23 21 23Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmChartBarFloatingIcon)
export default ForwardRef
