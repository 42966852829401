import * as React from 'react'
function VmDeleteBinIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 26 26',
        fill: 'none',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      'aria-label': props?.['aria-label'],
      d: 'M8.5 5.5C8.5 4.30653 8.97411 3.16193 9.81802 2.31802C10.6619 1.47411 11.8065 1 13 1C14.1935 1 15.3381 1.47411 16.182 2.31802C17.0259 3.16193 17.5 4.30653 17.5 5.5M8.5 5.5H17.5M8.5 5.5H4M17.5 5.5H22M4 5.5H1M4 5.5V22C4 22.7956 4.31607 23.5587 4.87868 24.1213C5.44129 24.6839 6.20435 25 7 25H19C19.7956 25 20.5587 24.6839 21.1213 24.1213C21.6839 23.5587 22 22.7956 22 22V5.5M22 5.5H25',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    })
  )
}
const ForwardRef = React.forwardRef(VmDeleteBinIcon)
export default ForwardRef
