import * as React from 'react'
function VmDrugsIcon({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        viewBox: '0 0 24 24',
        fill: 'none',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      d: 'M15.7931 2.575C14.7586 1.525 13.4138 1 12.1724 1C10.931 1 9.48276 1.525 8.55172 2.575L2.55172 8.455C0.482759 10.555 0.482759 13.81 2.55172 15.91C4.62069 18.01 7.82759 18.01 9.89655 15.91L15.7931 9.925C17.7586 7.93 17.7586 4.57 15.7931 2.575ZM14.3448 8.455L11.4483 11.395L7.72414 7.72L3.17241 12.34C3.17241 11.5 3.37931 10.555 4.10345 9.925L10 3.94C10.5172 3.415 11.3448 3.1 12.069 3.1C12.7931 3.1 13.6207 3.415 14.2414 3.94C15.4828 5.305 15.4828 7.195 14.3448 8.455ZM19.3103 6.355C19.3103 7.195 19.1034 7.93 18.8966 8.77C19.931 10.03 19.931 11.92 18.7931 13.075L15.8966 16.015L14.3448 14.44L11.4483 17.38C10.1034 18.745 8.24138 19.48 6.48276 19.48C6.68965 19.795 6.89655 20.11 7.2069 20.425C9.27586 22.525 12.4828 22.525 14.5517 20.425L20.4483 14.44C22.5172 12.34 22.5172 9.085 20.4483 6.985C19.931 6.775 19.6207 6.565 19.3103 6.355Z',
    })
  )
}
const ForwardRef = React.forwardRef(VmDrugsIcon)
export default ForwardRef
